import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { RouteGuard } from './core/auth/route.guard';
import { NotFoundComponent } from './features/errors/not-found/not-found.component';
import { LoggedInComponent } from './layout/logged-in/logged-in.component';
import { LoggedOutComponent } from './layout/logged-out/logged-out.component';
import { Role } from './shared/models/role';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/parts/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LoggedOutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/public/public.module').then((m) => m.PublicModule),
      },
    ],
  },
  {
    path: 'dashboard',
    component: LoggedInComponent,
    canActivate: [AuthGuard,RouteGuard],
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard,RouteGuard],
    component: LoggedInComponent,
    data: { roles: [Role.Admin] },
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'info',
    canActivate: [AuthGuard,RouteGuard],
    component: LoggedInComponent,
    data: { roles: [Role.Info] },
    loadChildren: () =>
      import('./features/info/info.module').then((m) => m.InfoModule),
  },
  
  {
    path: 'upload',
    canActivate: [AuthGuard,RouteGuard],
    component: LoggedInComponent,
    data: { roles: [Role.Upload] },
    loadChildren: () =>
      import('./features/upload/upload.module').then((m) => m.UploadModule),
  },
  {
    path: 'acl',
    canActivate: [AuthGuard,RouteGuard],
    component: LoggedInComponent,
    data: { roles: [Role.ACL] },
    loadChildren: () =>
      import('./features/acl/acl.module').then((m) => m.AclModule),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard,RouteGuard],
    component: LoggedInComponent,
    
    data: { roles: [Role.Reports] },
    loadChildren: () =>
      import('./features/Reports/report.module').then((m) => m.ReportsModule),
  },
  {
    path: 'parts',
    canActivate: [AuthGuard,RouteGuard],
    component: LoggedInComponent,
    data: { roles: [Role.Parts] },
    loadChildren: () =>
      import('./features/parts/parts.module').then((m) => m.PartsModule),
  },
  {
    path: 'engineer',
   // canActivate: [AuthGuard,RouteGuard],
    component: LoggedInComponent,
     //data: { roles: [Role.Enginner] },
    loadChildren: () =>
      import('./features/engineer/engineer.module').then((m) => m.EngineerModule),
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
