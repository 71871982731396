import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPartsState } from 'src/app/core/parts/parts.interfaces';

export const getPartsState = createFeatureSelector<IPartsState>('parts');

/**----------------------------------------------PART SEARCH----------------------------------------- */

export const PartSearchGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.partSearchGrid
);

export const PartSearchGridCurrentPageSelector = createSelector(
  getPartsState,
  (state) => state?.partSearchGrid?.currentPage
);
export const PartSearchGridCurrentPageSizeSelector = createSelector(
  getPartsState,
  (state) => state?.partSearchGrid?.pageSize
);
export const PartNumberSearchSelector = createSelector(
  getPartsState,
  (state) => state?.partNumberSearch
);


/**----------------------------------------------CROSS SEARCH----------------------------------------- */
export const CrossSearchGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.crossSearchGrid
);

export const CrossSearchGridCurrentPageSelector = createSelector(
  getPartsState,
  (state) => state?.crossSearchGrid?.currentPage
);
export const CrossSearchGridCurrentPageSizeSelector = createSelector(
  getPartsState,
  (state) => state?.crossSearchGrid?.pageSize
);
export const CrossNumberSearchSelector = createSelector(
  getPartsState,
  (state) => state?.crossSearchText
);

/**---------------------------------------------- COMPONENTS ----------------------------------------- */

export const ComponentsGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.componentsGrid
);
export const ComponentsPartDataDetailsSelector = createSelector(
  getPartsState,
  (state) => state?.partDetailsRecordGrid
);

export const ComponentsGridCurrentPageSelector = createSelector(
  getPartsState,
  (state) => state?.componentsGrid?.currentPage
);
export const ComponentsGridFilterSelector = createSelector(
  getPartsState,
  (state) => state?.componentsGrid?.filter
);
export const ComponentsGridSortSelector = createSelector(
  getPartsState,
  (state) => state?.componentsGrid?.sort
);
export const ComponentsGridCurrentPageSizeSelector = createSelector(
  getPartsState,
  (state) => state?.componentsGrid?.pageSize
);
export const ComponentsSelectedYearSelector = createSelector(
  getPartsState,
  (state) => state?.componentsSelectedYear
);

export const LoadedComponentSelector = createSelector(
  getPartsState,
  (state) => state?.loadedComponent
);

export const ComponentFiltersSelector = createSelector(
  ComponentsGridFilterSelector,
  ComponentsGridSortSelector,
  ComponentsGridCurrentPageSelector,
  ComponentsGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize:10,
    };
  }
);

/**---------------------------------------------- CBOM ----------------------------------------- */

export const CBOMGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.cbomGrid
);

export const GetContractManufacturerSelector = createSelector(
  getPartsState,
  (state) => state?.contractManufacturer
);

export const CBomGridCurrentPageSelector = createSelector(
  getPartsState,
  (state) => state?.cbomGrid?.currentPage
);
export const CBomGridFilterSelector = createSelector(
  getPartsState,
  (state) => state?.cbomGrid?.filter
);
export const CBomGridSortSelector = createSelector(
  getPartsState,
  (state) => state?.cbomGrid?.sort
);
export const CBomGridCurrentPageSizeSelector = createSelector(
  getPartsState,
  (state) => state?.cbomGrid?.pageSize
);
export const CBomFiltersSelector = createSelector(
  CBomGridFilterSelector,
  CBomGridSortSelector,
  CBomGridCurrentPageSelector,
  CBomGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize:10,
    };
  }
);
/*---------------------------------------alerts------------------------*/
export const AlertGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.alertGrid
);
export const P5AlertGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.p5alertGrid
);
export const AlertDetailsGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.alertDetailsGrid
);
export const P5AlertDetailsGridDataSelector = createSelector(
  getPartsState,
  (state) => state?.p5alertDetailsGrid
);
export const AlertDataDataSelector = createSelector(
  getPartsState,
  (state) => state?.alertData
);
export const AlertGridDeleteSelector = createSelector(
  getPartsState,
  (state) => state?.alertGrid?.delete
);


export const alertDetailsFilterSelector = createSelector(
  getPartsState,
  (state) => state?.alertDetailsGrid?.filter
);
export const alertDetailsSortSelector = createSelector(
  getPartsState,
  (state) => state?.alertDetailsGrid?.sort
);

export const alertDetailsGridCurrentPageSelector = createSelector(
  getPartsState,
  (state) => state?.alertDetailsGrid?.currentPage
);
export const alertDetailsGridCurrentPageSizeSelector = createSelector(
  getPartsState,
  (state) => state?.alertDetailsGrid?.pageSize
);

export const AlertDetailsFiltersSelector = createSelector(
  alertDetailsFilterSelector,
  alertDetailsSortSelector,
  alertDetailsGridCurrentPageSelector,
  alertDetailsGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

export const P5alertDetailsFilterSelector = createSelector(
  getPartsState,
  (state) => state?.p5alertDetailsGrid?.filter
);
export const P5alertDetailsSortSelector = createSelector(
  getPartsState,
  (state) => state?.p5alertDetailsGrid?.sort
);

export const P5alertDetailsGridCurrentPageSelector = createSelector(
  getPartsState,
  (state) => state?.p5alertDetailsGrid?.currentPage
);
export const P5alertDetailsGridCurrentPageSizeSelector = createSelector(
  getPartsState,
  (state) => state?.p5alertDetailsGrid?.pageSize
);
export const P5AlertDetailsFiltersSelector = createSelector(
  P5alertDetailsFilterSelector,
  P5alertDetailsSortSelector,
  P5alertDetailsGridCurrentPageSelector,
  P5alertDetailsGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

export const AlertDetailsDataSelector = createSelector(
  getPartsState,
  (state) => state?.alertDetailsData
);

export const SendSelectedAlertSelector = createSelector(
  getPartsState,
  (state) => state?.alertDetailsGrid.sendselected
);

export const p5AlertPartDetailsSelector = createSelector(
  getPartsState,
  (state) => state?.p5AlertPartDetails
);


export const cmtAlertPartDetailsSelector = createSelector(
  getPartsState,
  (state) => state?.cmtAlertPartDetails
);

export const addCMTAlertPartCommentSelector = createSelector(
  getPartsState,
  (state) => state?.partAlertComment
);

export const getUsersSelector = createSelector(
  getPartsState,
  (state) => state?.users
);
export const getPrioritiesSelector = createSelector(
  getPartsState,
  (state) => state?.priorities
);
export const editAlertDetailsSelector = createSelector(
  getPartsState,
  (state) => state?.editAlertDetails
);