<kendo-stepper
  [steps]="steps"
  [(currentStep)]="currentStep"
  [stepType]="stepType"
  [linear]="false"
  successIcon="k-icon k-i-check-circle"
  errorIcon="k-icon k-i-close-outline"
>
  <ng-template kendoStepperLabelTemplate let-step>
    <span *ngIf="currentStep === step.stepNumber">{{ step.label }}</span>
  </ng-template>
</kendo-stepper>
