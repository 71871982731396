import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { IUser } from '../user.interface';
import { UserService } from '../user.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  ForgetPassword,
  ForgetPasswordError,
  ForgetPasswordSuccess,
  getUserEmailByCode,
  getUserEmailByCodeError,
  getUserEmailByCodeSuccess,
  GetUserRoles,
  GetUserRolesError,
  GetUserRolesSuccess,
  redirect,
  ResetPassword,
  ResetPasswordError,
  ResetPasswordSuccess,
  UserActionTypes,
  // userLoggedInComplete,
  userLoggedInFailed,
} from './user.actions';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/shared/shared.interface';
import { getRedirectUrl } from './user.selectors';
import { filter } from 'lodash';
import { Router } from '@angular/router';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { effects } from 'src/app/stores/effects';
import { ServiceMiddleware } from '../../Admin/service.layer/api.services.middleware';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  APIURL: string;
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store<IAppState>,
    private router: Router,
    private serviceMiddleware: ServiceMiddleware,
  ) { }

  forgertPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.USER_FOTGET_PASSWORD),
      switchMap((action: ForgetPassword) => {
        this.APIURL = '/admin/user/forget-password';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new ForgetPasswordSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ForgetPasswordError(errorResponse))
            )
          );
      })
    );
  });
  getUserEmailByCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.GET_USER_EMAIL_BY_CODE),
      switchMap((action: getUserEmailByCode) => {
        this.APIURL = '/admin/user/reset-email/';
        return this.serviceMiddleware.Get(this.APIURL + action.code)
          .pipe(map(
            (dataResponse: any) => new getUserEmailByCodeSuccess(dataResponse)
          ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new getUserEmailByCodeError(errorResponse))
            )
          );
      })
    );
  });
  getUserRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.GET_USER_ROLE),
      switchMap((action: GetUserRoles) => {
        this.APIURL = '/getUserRules';
        return this.serviceMiddleware.AddRecord(this.APIURL,action.payload)
          .pipe(map(
            (dataResponse: any) => new GetUserRolesSuccess(dataResponse)
          ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetUserRolesError(errorResponse))
            )
          );
      })
    );
  });
  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.USER_RESET_PASSWORD),
      switchMap((action: ResetPassword) => {
        this.APIURL = '/admin/user/reset-password';
        return this.serviceMiddleware.EditRecord(this.APIURL,action.payload)
          .pipe(map(
            (dataResponse: any) => new ResetPasswordSuccess(dataResponse)
          ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new ResetPasswordError(errorResponse))
            )
          );
      })
    );
  });
  loggedInComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.USER_LOGGED_IN_COMPLETE),
      map(() => {

        return redirect('/parts/dashboard');
      })
    )
  );

  /* userAfterLoggedInComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.USER_AFTER_LOGGED_IN_COMPLETE),
      map(() => redirect())
    )
  );
 */

  redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.REDIRECT),
        withLatestFrom(this.store.pipe(select(getRedirectUrl))),
        tap(([action, redirectUrl]: [{ redirectUrl?: string }, string]) => {
          const uri = action.redirectUrl ? action.redirectUrl : redirectUrl;
          this.router.navigateByUrl(uri);
        })
      ),
    { dispatch: false }
  );
}
