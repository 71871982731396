import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import {
  IManufacturers,
  IManufacturesFlatObject,
} from './manufacturer.interfaces';
import { saveAs } from 'file-saver';

export function flatManData(data: IManufacturers[]): IManufacturesFlatObject[] {
  let flattedData: IManufacturesFlatObject[] = [];
  data?.forEach((element) => {
    var elem: IManufacturesFlatObject = {
      manId: element.manId,
      manName: element.manName,
      statusId: element.manufacturerStatus.statusId,
      statusName: element.manufacturerStatus.statusName,
    };
    flattedData.push(elem);
  });
  return flattedData;
}

export function mapKendoSortToAPISort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {
    case 'statusName':
      return { columnName: 'manufacturerStatus.statusName', direction: dir };
    default:
      return { columnName: kendoSort?.field, direction: dir };
  }
}

export function mapKendoFilterToAPI(gridFilters: any) {
  var kendoFilter = _.isArray(gridFilters) ? gridFilters[0] : gridFilters;
  var kendoFilters = _.isArray(kendoFilter?.filters)
    ? kendoFilter?.filters[0]
    : kendoFilter?.filters;
  switch (kendoFilters?.field) {
    case 'manName':
      return [
        {
          columnName: 'manName',
          value: kendoFilters?.value,
        },
      ];
    case 'statusName':
      return [
        {
          columnName: 'manufacturerStatus.statusName',
          value: kendoFilters?.value,
        },
      ];
    default:
      return [];
  }
}
