import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: true,
  devTool: false,
  baseUrls: {
  serviceSecurity: 'https://wabtec.siliconexpert.com',
  serviceURL: 'https://wabtec.siliconexpert.com/api/v1',
   },
   ConfigParams: {
   infoUserEmail: 'alejandra.montoya@Wabtec.com',
   infouser: 'Alejandra Montoya',
   projectName:'wabtec',
   domain:'https://wabtec.siliconexpert.com'
   },
  powerBiReports: {
    dashboardReportId: 'd350c609-e2c5-4abd-888a-c3a976c244f8',
  },
};
