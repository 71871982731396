import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KJUR as jwtKJUR } from 'jsrsasign';
import { camelCase, mapKeys } from 'lodash-es';
import { IAppState } from 'src/app/shared/shared.interface';
import { GetUserRoles } from '../user/store/user.actions';
import { UserRolesSelector } from '../user/store/user.selectors';
import { IAuthResult } from './auth.interface';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  constructor(
    private store: Store<IAppState>,) { }

  private ACCESS_TOKEN_STORAGE_KEY = 'accessToken';
  private USER_STORAGE_ROLE = 'userRoles';
  private EXPIRES_AT_STORAGE_KEY = 'accessExpiresAt';
  private REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';
  private EXPIRES_AT_REFRESH_KEY = 'refreshExpiresAt';
  public TOKEN_REQUEST_TIME = 'tokenRequestTime';

  public addStorageListener() {
    window.addEventListener('storage', this.verifyStorage);
  }

  public removeStorageListener() {
    window.removeEventListener('storage', this.verifyStorage);
  }

  public verifyStorage(storageEvent: StorageEvent) {
    if (
      this.isTokenKey(storageEvent.key!) &&
      (!storageEvent.newValue || storageEvent.newValue === null)
    ) {
      this.removeStorageListener();
    }
  }
  private EncryptUserRoles(userRoles: string, key: string) {
   
    const encryptedText = CryptoJS.AES.encrypt(JSON.stringify({ userRoles }), key).toString();
    return encryptedText;
   // this.DecryptUserRoles(encryptedText,key)
  }
  private DecryptUserRoles(userRoles: any, key: string) {
 
    const decryptedtxt = CryptoJS.AES.decrypt(userRoles, key).toString(CryptoJS.enc.Utf8);
    
    return decryptedtxt;
  }
  private isTokenKey(key: string) {
    const storageKeys = [
      this.ACCESS_TOKEN_STORAGE_KEY,
      this.REFRESH_TOKEN_STORAGE_KEY,
    ];
    return storageKeys.includes(key);
  }

  public setTokens(authResult: IAuthResult) {
 
    this.storeAccessToken(authResult.id_token);
    //this.store.dispatch(new GetUserRoles(authResult.id_token))
    var dycrptedToken = this.decodeToken(authResult.id_token);
    const encryptedRoles = this.EncryptUserRoles(authResult.menu, dycrptedToken.token.sub)
    this.storeRoles(encryptedRoles);

    this.storeAccessTokenExpirationTime(authResult);
    this.storeRefreshToken(authResult.refresh_token!);
    //this.storeRefreshTokenExpirationTime(authResult);
  }

  private storeToken(key: string, token: string) {

    sessionStorage.setItem(key, token);
  }

  public getAccessToken() {
    return sessionStorage.getItem(this.ACCESS_TOKEN_STORAGE_KEY);
  }
  public getUserRoles() {
    var accessToken = this.getAccessToken();
    var userRoles;
    if (accessToken !== null) {
      const dycrptedToken = this.decodeToken(accessToken);
       userRoles = this.DecryptUserRoles(sessionStorage.getItem(this.USER_STORAGE_ROLE), dycrptedToken.token.sub);
    }
    return userRoles|| '';
  }
  public getRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN_STORAGE_KEY);
  }

  public getTokenRequestTime() {
    return sessionStorage.getItem(this.TOKEN_REQUEST_TIME) || null;
  }

  private storeAccessToken(accessToken: string) {
    this.storeToken(this.ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }
  private storeRoles(roles: any) {
 
    this.storeToken(this.USER_STORAGE_ROLE,roles);
  }
  private getExpirationTime(tokenRequestTime: number, expiresIn: number) {
    return tokenRequestTime + expiresIn * 1000;
  }

  private camelCaseObj(obj: object) {
    return mapKeys(obj, (value: string, key: string) => camelCase(key));
  }

  private decodeToken(token: string) {
    const jwtParse = jwtKJUR.jws.JWS.parse;
    const { payloadObj, headerObj } = jwtParse(token);

    const formattedToken = this.camelCaseObj(payloadObj!);
    const formattedHeader = this.camelCaseObj(headerObj);

    return {
      token: formattedToken,
      header: formattedHeader,
    };
  }

  public getExpirationTimeFromToken(accessToken: string) {
    const decodedAccessToken = this.decodeToken(accessToken);

    return decodedAccessToken.token.exp;
  }

  public setTokenRequestTime() {
    const date = new Date();

    sessionStorage.setItem(this.TOKEN_REQUEST_TIME, `${date.getTime()}`);
  }

  public handleExpirationTime(authResult: IAuthResult) {
    const tokenRequestTime = parseInt(this.getTokenRequestTime()!, 10);
    const expirationTime = isNaN(tokenRequestTime)
      ? this.getExpirationTimeFromToken(authResult.id_token)
      : this.getExpirationTime(tokenRequestTime, authResult.expires_in!);

    return JSON.stringify(expirationTime);
  }

  private storeAccessTokenExpirationTime(authResult: IAuthResult) {

    const expirationTime = this.handleExpirationTime(authResult);

    sessionStorage.setItem(this.EXPIRES_AT_STORAGE_KEY, expirationTime);
  }

  private storeRefreshToken(refreshToken: string) {
    this.storeToken(this.REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  }

  private storeRefreshTokenExpirationTime(authResult: IAuthResult) {
    const decodedRefreshToken = this.decodeToken(authResult.refresh_token!);
    const expirationTime = JSON.stringify(decodedRefreshToken.token.exp * 1000);

    sessionStorage.setItem(this.EXPIRES_AT_REFRESH_KEY, expirationTime);
  }

  public isValidToken(token: string) {
    const expirationTime = sessionStorage.getItem(this.EXPIRES_AT_STORAGE_KEY);
    if (expirationTime && parseInt(expirationTime.toString()) > Math.round(Date.now() / 1000)) {
      return true;
    } else {
      return false;
    }
  }

  public getValidAccessToken() {
    const accessToken = this.getAccessToken();
    return this.isValidToken(accessToken!) ? accessToken : null;
  }

  public getValidRefreshToken() {
    const refreshToken = this.getRefreshToken();
    return this.isValidToken(refreshToken!) ? refreshToken : null;
  }
}
