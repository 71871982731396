import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ActionTypes,
  ComponentsExportGridError,
  ComponentsExportGridSuccess,
  ComponentsLoadData,
  ComponentsLoadDataError,
  ComponentsLoadDataSuccess,
  CrossSearchExportGridError,
  CrossSearchExportGridSucees,
  CrossSearchLoadData,
  CrossSearchLoadDataError,
  CrossSearchLoadDataSuccess,
  LoadComponent,
  LoadComponentError,
  LoadComponentSuccess,
  PartSearchExportGridError,
  PartSearchExportGridSucees,
  PartSearchLoadData,
  PartSearchLoadDataError,
  PartSearchLoadDataSuccess,
  ComponentsExportGrid,
  CbomLoadData,
  CbomLoadDataSuccess,
  CbomLoadDataError,
  GetContractManufacturerSuccess,
  GetContractManufacturerError,
  PartCBOMExportGrid,
  PartCBOMExportGridError,
  PartCBOMExportGridSucees,
  AlertLoadData,
  AlertLoadDataSucees,
  AlertLoadDataError,
  AlertDelete,
  AlertDeleteSuccess,
  AlertDeleteError,
  AlertAdd,
  AlertAddSuccess,
  AlertAddError,
  AlertEdit,
  AlertEditSuccess,
  AlertEditError,
  AlertDetailsLoadDataSucees,
  AlertDetailsLoadData,
  AlertDetailsLoadDataError,
  ALertExportAllGrid,
  ALertExportAllGridSucees,
  ALertExportAllGridError,
  GetAlertDetailsByIdSuccess,
  GetAlertDetailsByIdError,
  GetAlertDetailsById,
  SendSelectedAlerts,
  SendSelectedAlertsSucees,
  SendSelectedAlertsError,
  p5AlertLoadData,
  p5AlertLoadDataSucees,
  p5AlertLoadDataError,
  GetP5AlertDetailsById,
  GetP5AlertDetailsByIdSuccess,
  GetP5AlertDetailsByIdError,
  GetCMTAlertPartDetailsById,
  GetCMTAlertPartDetailsByIdSuccess,
  GetCMTAlertPartDetailsByIdError,
  GetP5AlertPartDetailsById,
  GetP5AlertPartDetailsByIdSuccess,
  GetP5AlertPartDetailsByIdError,
  AddCMTAlertPartComment,
  AddCMTAlertPartCommentSuccess,
  AddCMTAlertPartCommentError,
  GetUsers,
  GetUsersSuccess,
  GetUsersError,
  PartDetailsRecordLoadData,
  PartDetailsRecordLoadDataSuccess,
  PartDetailsRecordLoadDataError,
  GetPriorities,
  GetPrioritiesSuccess,
  GetPrioritiesError,
  EditAlertDetailsSuccess,
  EditAlertDetailsError,
  EditAlertDetails,
} from './parts.action';
import { PartSearchService } from 'src/app/core/parts/part-search/part-search.service';
import { CrossSearchService } from 'src/app/core/parts/cross-search/cross-search.service';
import { ComponentsService } from 'src/app/core/parts/components/components.service';
import { ServiceMiddleware } from 'src/app/core/Admin/service.layer/api.services.middleware';
import { CbomService } from 'src/app/core/parts/cbom/cbom.service';
import { HttpErrorResponse } from '@angular/common/http';
import { gridRequest } from 'src/app/core/grid.request';
import { gridRequestAlertDetails } from 'src/app/core/parts/alerts/alertdetails.request';
import { mapSort, mapFilter, MapMultipleFilter } from 'src/app/core/utils';
import {
  mapAlertsFilter,
  mapCmtAlertsSort,
  mapP5AlertsSort,
} from 'src/app/core/parts/alerts/alertdetails.mapper';

@Injectable({
  providedIn: 'root',
})
export class PartsEffects {
  APIURL: string;
  constructor(
    private actions$: Actions,
    private partSearchService: PartSearchService,
    private crossSearchService: CrossSearchService,
    private componentsService: ComponentsService,
    private cbomService: CbomService,
    private serviceMiddleware: ServiceMiddleware
  ) { }

  /*-------------------------------------------------------Part Search-----------------------------------------------------------------------*/
  getPartSearchGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PART_SEARCH_LOAD_DATA),
      switchMap((action: PartSearchLoadData) => {
        return this.getPartSearchList(action?.page, action?.size).pipe(
          map((data: any) => {
            if (data.Status.Success) {
              return new PartSearchLoadDataSuccess(data);
            }
            return new PartSearchLoadDataError(data.Status);
          }),
          catchError((errorResponse: any) =>
            of(new PartSearchLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  public getPartSearchList(page?: number, size?: number) {
    return this.partSearchService.getPartSearchList(page, size);
  }

  ExportPartSearchGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PART_SEARCH_EXPORT_DATA),
      switchMap(() => {
        return this.partSearchService.exportList().pipe(
          map((data: any) => new PartSearchExportGridSucees(data)),
          catchError((errorResponse: any) =>
            of(new PartSearchExportGridError(errorResponse))
          )
        );
      })
    );
  });

  /*-------------------------------------------------------Cross Search-----------------------------------------------------------------------*/
  getCrossSearchGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CROSS_SEARCH_LOAD_DATA),
      switchMap((action: CrossSearchLoadData) => {
        return this.getCrossSearchList(action?.page, action?.size).pipe(
          map((data: any) => {
            if (data.Status.Success) {
              return new CrossSearchLoadDataSuccess(data);
            }
            return new CrossSearchLoadDataError(data.Status);
          }),
          catchError((errorResponse: any) =>
            of(new CrossSearchLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  public getCrossSearchList(page?: number, size?: number) {
    return this.crossSearchService.getCrossSearchList(page, size);
  }

  ExportCrossSearchGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CROSS_SEARCH_EXPORT_DATA),
      switchMap(() => {
        return this.crossSearchService.exportList().pipe(
          map((data: any) => new CrossSearchExportGridSucees(data)),
          catchError((errorResponse: any) =>
            of(new CrossSearchExportGridError(errorResponse))
          )
        );
      })
    );
  });

  /*------------------------------------------------------- Components -----------------------------------------------------------------------*/
  getPartDetailsRecordData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PART_DETAILS_RECORD_LOAD_DATA),
      switchMap((action: PartDetailsRecordLoadData) => {
        this.APIURL = '/cmt/PartsComponent/report/details/record/'+ action.RecordId+'/year/'+action.yearId; 
        return this.serviceMiddleware.Get(this.APIURL)
          .pipe(
            map((data: any) => {
              return new PartDetailsRecordLoadDataSuccess(data);
            }),
            catchError((errorResponse: any) =>
              of(new PartDetailsRecordLoadDataError(errorResponse))
            )
          );
      })
    );
  });

  getComponentsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.COMPONENTS_LOAD_DATA),
      switchMap((action: ComponentsLoadData) => {
        this.APIURL = '/cmt/PartsComponent/report/' + action.yearId; 
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request)
          .pipe(
            map((data: any) => {
              return new ComponentsLoadDataSuccess(data);
            }),
            catchError((errorResponse: any) =>
              of(new ComponentsLoadDataError(errorResponse))
            )
          );
      })
    );
  });

  ExportComponentsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.COMPONENTS_EXPORT_DATA),
      switchMap((action: ComponentsExportGrid) => {
        this.APIURL = `/cmt/PartsComponent/exportreport/${action.yearId}`;
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL, request).pipe(
          map(
            (data: any) =>
              new ComponentsExportGridSuccess(data, action.filename)
          ),
          catchError((errorResponse: any) =>
            of(new ComponentsExportGridError(errorResponse))
          )
        );
      })
    );
  });

  LoadComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.LOAD_COMPONENT),
      switchMap((action: LoadComponent) => {
        return this.componentsService.LoadComponent(action.comId).pipe(
          map((data: any) => new LoadComponentSuccess(data)),
          catchError((errorResponse: any) =>
            of(new LoadComponentError(errorResponse))
          )
        );
      })
    );
  });

  /*------------------------------------------------------- CBOM -----------------------------------------------------------------------*/
  getCbomGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CBOM_LOAD_DATA),
      switchMap((action: CbomLoadData) => {
        this.APIURL = '/cmt/cbomParts/report/'+action.yearId+'/cm/'+action.manId;
         const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((data: any) => {
            return new CbomLoadDataSuccess(data);
          }),
          catchError((errorResponse: any) =>
            of(new CbomLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  GetContractManufacturer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_CONTRACT_MANFACTURER),
      switchMap(() => {
        this.APIURL = '/admin/contractmanufacturer';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map(
            (dataResponse: any) =>
              new GetContractManufacturerSuccess(dataResponse)
          ),
          catchError((errorResponse: any) =>
            of(new GetContractManufacturerError(errorResponse))
          )
        );
      })
    );
  });

  ExportCBOMGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PART_CBOM_EXPORT_DATA),
      switchMap((action: PartCBOMExportGrid) => {

        this.APIURL = '/cmt/cbomParts/exportCBomReport/'+action.yearId+'/cm/'+action.cm;
        const request = gridRequest(
          action?.page,
          action?.size,
          mapSort(action?.sort),
          MapMultipleFilter(action?.filter)
        );
        return this.serviceMiddleware.exportFilteredList(this.APIURL,request).pipe(
          map(
            (data: any) => new PartCBOMExportGridSucees(data, action.filename)
          ),
          catchError((errorResponse: any) =>
            of(new PartCBOMExportGridError(errorResponse))
          )
        );
      })
    );
  });
  /*--------------------------------alert----------------------------------*/
  ExportAllAlertsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EXPORT_ALL_ALERT_DATA),
      switchMap((action: ALertExportAllGrid) => {
        var request;
        if (action.P5AlertId === 0) {
          request = gridRequestAlertDetails(
            action?.page,
            action?.size,
            mapSort(action?.sort),
            mapFilter(action?.filter),
            action?.CmtAlertId,
            action?.selectedALert
          );
          this.APIURL = '/cmt/alert/export';
        }
        else {
          request = gridRequestAlertDetails(
            action?.page,
            action?.size,
            mapSort(action?.sort),
            mapFilter(action?.filter),
            action?.P5AlertId,
            action?.selectedALert
          );
          this.APIURL = '/cmt/p5Alert/export';
        }
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL, request)
          .pipe(
            map(
              (data: any) => new ALertExportAllGridSucees(data, action.fileName)
            ),
            catchError((errorResponse: any) =>
              of(new ALertExportAllGridError(errorResponse))
            )
          );
      })
    );
  });

  SendSelectedAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.SEND_SELECTED_ALERT),
      switchMap((action: SendSelectedAlerts) => {
        if (action.cmtALertId !== 0) {
          this.APIURL = '/cmt/alert/sendAlertMail';
        }
        else {
          this.APIURL = '/cmt/p5Alert/sendAlertMail';
        }
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map(
              (dataResponse: any) => new SendSelectedAlertsSucees(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new SendSelectedAlertsError(errorResponse))
            )
          );
      })
    );
  });
  getAlertGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ALERT_LOAD_DATA),
      switchMap((action: AlertLoadData) => {
        this.APIURL = '/cmt/alert/fillAlertGrid';
        const request = gridRequest(action?.page, action?.size);
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new AlertLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new AlertLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  getP5AlertGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.P5_ALERT_LOAD_DATA),
      switchMap((action: p5AlertLoadData) => {
        this.APIURL = '/cmt/p5Alert/categories';
        return this.serviceMiddleware.Get(this.APIURL).pipe(
          map((dataResponse: any) => new p5AlertLoadDataSucees(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new p5AlertLoadDataError(errorResponse))
          )
        );
      })
    );
  });
  DeleteAlertGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ALERT_DELETE_RECORD),
      switchMap((action: AlertDelete) => {
        this.APIURL = '/cmt/alert/deleteAlert/';
        return this.serviceMiddleware
          .DeleteRecord(this.APIURL + action.alertID)
          .pipe(
            map((dataResponse: any) => new AlertDeleteSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AlertDeleteError(errorResponse))
            )
          );
      })
    );
  });

  AddAlertGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ALERT_ADD_RECORD),
      switchMap((action: AlertAdd) => {
        this.APIURL = '/cmt/alert';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new AlertAddSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AlertAddError(errorResponse))
            )
          );
      })
    );
  });

  EditAlertGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ALERT_EDIT_RECORD),
      switchMap((action: AlertEdit) => {
        this.APIURL = '/cmt/alert/updateAlert';
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new AlertEditSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AlertEditError(errorResponse))
            )
          );
      })
    );
  });
  EditAlertDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.EDIT_ALERT_DETAILS),
      switchMap((action: EditAlertDetails) => {
        this.APIURL = `/cmt/alert/part/details/updateWithNotify/${action?.alertId}`;
        return this.serviceMiddleware
          .EditRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new EditAlertDetailsSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new EditAlertDetailsError(errorResponse))
            )
          );
      })
    );
  });

  /*-----------------------------alert details---------------------------------*/
  getAlertDetailsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ALERT_DETAILS_LOAD_DATA),
      switchMap((action: AlertDetailsLoadData) => {

        this.APIURL = '/cmt/alert/getAlertPartListData/';
        const request = gridRequest(
          action?.page,
          action?.size,
          mapCmtAlertsSort(action?.sort),
          mapAlertsFilter(action?.filter)
        );
        return this.serviceMiddleware
          .getList(this.APIURL + action.alertId, request)
          .pipe(
            map(
              (dataResponse: any) =>
                new AlertDetailsLoadDataSucees(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AlertDetailsLoadDataError(errorResponse))
            )
          );
      })
    );
  });
  GetAlertGridRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_ALERT_RECORD_BY_ID),
      switchMap((action: GetAlertDetailsById) => {
        this.APIURL = '/cmt/alert/';
        return this.serviceMiddleware.Get(this.APIURL + action.AlertId).pipe(
          map(
            (dataResponse: any) => new GetAlertDetailsByIdSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetAlertDetailsByIdError(errorResponse))
          )
        );
      })
    );
  });
  getP5AlertDetailsGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_P5_ALERT_RECORD_BY_ID),
      switchMap((action: GetP5AlertDetailsById) => {
        this.APIURL = '/cmt/p5Alert/page';
        const request = gridRequestAlertDetails(
          action?.page,
          action?.size,
          mapP5AlertsSort(action?.sort),
          mapAlertsFilter(action?.filter),
          action?.parentId
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map(
            (dataResponse: any) =>
              new GetP5AlertDetailsByIdSuccess(dataResponse)
          ),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new GetP5AlertDetailsByIdError(errorResponse))
          )
        );
      })
    );
  });

  getCMTAlertPartDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_CMT_ALERT_PART_DETAILS_BY_ID),
      switchMap((action: GetCMTAlertPartDetailsById) => {
        this.APIURL = `/cmt/alert/getAlertPartDetails/${action?.alertPartDetailsId}`;

        return this.serviceMiddleware
          .Get(this.APIURL)
          .pipe(
            map(
              (dataResponse: any) =>
                new GetCMTAlertPartDetailsByIdSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetCMTAlertPartDetailsByIdError(errorResponse))
            )
          );
      })
    );
  });

  getP5AlertPartDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_P5_ALERT_PART_DETAILS_BY_ID),
      switchMap((action: GetP5AlertPartDetailsById) => {
        this.APIURL = `/cmt/p5Alert/details/${action?.alertPartDetailsId}`;

        return this.serviceMiddleware
          .Get(this.APIURL)
          .pipe(
            map(
              (dataResponse: any) =>
                new GetP5AlertPartDetailsByIdSuccess(dataResponse)
            ),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetP5AlertPartDetailsByIdError(errorResponse))
            )
          );
      })
    );
  });

  addCMtAlertPartComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.ADD_CMT_ALERT_PART_COMMENT),
      switchMap((action: AddCMTAlertPartComment) => {
        this.APIURL = '/cmt/alert/comment';
        return this.serviceMiddleware
          .AddRecord(this.APIURL, action.payload)
          .pipe(
            map((dataResponse: any) => new AddCMTAlertPartCommentSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new AddCMTAlertPartCommentError(errorResponse))
            )
          );
      })
    );
  });

  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_USERS),
      switchMap((action: GetUsers) => {
        this.APIURL = '/admin/user/wabtec';
        return this.serviceMiddleware
          .Get(this.APIURL)
          .pipe(
            map((dataResponse: any) => new GetUsersSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetUsersError(errorResponse))
            )
          );
      })
    );
  });
  getPriorities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_PRIORITIES),
      switchMap((action: GetPriorities) => {
        this.APIURL = '/cmt/alert/priorities';
        return this.serviceMiddleware
          .Get(this.APIURL)
          .pipe(
            map((dataResponse: any) => new GetPrioritiesSuccess(dataResponse)),
            catchError((errorResponse: HttpErrorResponse) =>
              of(new GetPrioritiesError(errorResponse))
            )
          );
      })
    );
  });
}
