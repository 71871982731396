import { Component, Input, OnInit } from '@angular/core';
import { StepType } from '@progress/kendo-angular-layout';
import { ISTEPPER } from './stepper.interface';
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() public currentStep = 0;
  @Input() public stepType: StepType = 'full';
  @Input() public steps: ISTEPPER[] = [];

  constructor() {}

  ngOnInit(): void {
    // this.steps = [
    //   { label: "Map Columns", disabled:true, isValid:true},
    //   { label: "labwl2", icon: "" ,isValid: true,disabled:true},
    //   { label: "",optional: true,isValid: false,disabled:true },
    //   { label: "" ,disabled:true},
    //   { label: "" ,disabled:true},
    // ];

    this.addStepNumber();
  }
  addStepNumber() {
    this.steps.forEach((step: any, i: Number) => {
      step.stepNumber = i;
    });
  }
}
