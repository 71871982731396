import { Action } from '@ngrx/store';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export enum ActionTypes {
  ///////////////////////////////////////////////// All Reports /////////////////////////////////////////////////
  EXPORT_REPORT_By_ID = '[REPORTS] EXPORT_REPORT_By_ID',
  EXPORT_REPORT_By_ID_SUCCESS = '[REPORTS] EXPORT_REPORT_By_ID_SUCCESS',
  EXPORT_REPORT_By_ID_ERROR = '[REPORTS] EXPORT_REPORT_By_ID_ERROR',

  EXPORT_PIVOTE_TABLE = '[REPORTS] EXPORT_PIVOTE_TABLE',
  EXPORT_PIVOTE_TABLE_SUCCESS = '[REPORTS] EXPORT_PIVOTE_TABLE_SUCCESS',
  EXPORT_PIVOTE_TABLE_ERROR = '[REPORTS] EXPORT_PIVOTE_TABLE_ERROR',

  LOAD_PIVOTE_TABLE = '[REPORTS] LOAD_PIVOTE_TABLE',
  LOAD_PIVOTE_TABLE_SUCCESS = '[REPORTS] LOAD_PIVOTE_TABLE_SUCCESS',
  LOAD_PIVOTE_TABLE_ERROR = '[REPORTS] LOAD_PIVOTE_TABLE_ERROR',
  GET_SOURCE_COLUMNS = 'GET_SOURCE_COLUMNS',
  GET_SOURCE_COLUMNS_SUCCESS = 'GET_SOURCE_COLUMNS_SUCCESS',
  GET_SOURCE_COLUMNS_ERROR = 'GET_SOURCE_COLUMNS_ERROR',
}

//////////////////////////////////////////Reports ////////////////////////////////

// assembly usage
export class ExportReportById implements Action {
  readonly type = ActionTypes.EXPORT_REPORT_By_ID;
  constructor(public fileName?: any,public yearId?:number,
     public reportId?: number,
     public reporType?:string,
     public payload?:any
     ) {}
}

export class ExportReportByIdSucees implements Action {
  readonly type = ActionTypes.EXPORT_REPORT_By_ID_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}

export class ExportReportByIdError implements Action {
  readonly type = ActionTypes.EXPORT_REPORT_By_ID_ERROR;
  constructor(public payload: any) {}
}

// GET PIVOTE TABLE

export class ExportPivoteTable implements Action {
  readonly type = ActionTypes.EXPORT_PIVOTE_TABLE;
  constructor(public payload?:any,public fileName?: any) {}
}

export class ExportPivoteTableSucees implements Action {
  readonly type = ActionTypes.EXPORT_PIVOTE_TABLE_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}

export class ExportPivoteTableError implements Action {
  readonly type = ActionTypes.EXPORT_PIVOTE_TABLE_ERROR;
  constructor(public payload: any) {}
}

export class LoadPivoteTable implements Action {
  readonly type = ActionTypes.LOAD_PIVOTE_TABLE;
  constructor(public payload?:any) {}
}

export class LoadPivoteTableSuccees implements Action {
  readonly type = ActionTypes.LOAD_PIVOTE_TABLE_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadPivoteTableError implements Action {
  constructor(public payload: any) {}
  readonly type = ActionTypes.LOAD_PIVOTE_TABLE_ERROR;
}
export class getSourceColumns implements Action {
  readonly type = ActionTypes.GET_SOURCE_COLUMNS;
  constructor(public sourceId: number) {}
}

export class getSourceColumnsSucees implements Action {
  readonly type = ActionTypes.GET_SOURCE_COLUMNS_SUCCESS;
  constructor(public payload: any) {}
}

export class getSourceColumnsError implements Action {
  readonly type = ActionTypes.GET_SOURCE_COLUMNS_ERROR;
  constructor(public payload: any) {}
}
