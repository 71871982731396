import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthTokenService } from '../auth/auth-token.service';

enum AccessToken {
  SEND = 1,
  DONT_SEND = 0,
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private authTokenService: AuthTokenService
  ) {}

  /**
   * http GET wrapper method
   * @param url -  the service url
   * @param params - url params to send with request
   * @param headers - header params to send with request
   */
  public get<T>(
    url: string,
    params?: {},
    headers?: {},
    observe?: 'body'
  ): Observable<T>;
  public get<T>(
    url: string,
    params?: {},
    headers?: {},
    observe?: 'response'
  ): Observable<HttpResponse<T>>;
  public get<T>(
    url: string,
    params?: {},
    headers?: {},
    observe?: any
  ): Observable<any> {
    return this.http.get<T>(url, {
      ...this.getOptions(params, headers, AccessToken.SEND),
      ...(observe ? { observe } : {}),
    });
  }

  public getPublic<T>(
    url: string,
    params?: {},
    headers?: {},
    observe?: 'body'
  ): Observable<T>;
  public getPublic<T>(
    url: string,
    params?: {},
    headers?: {},
    observe?: 'response'
  ): Observable<HttpResponse<T>>;
  public getPublic<T>(
    url: string,
    params?: {},
    headers?: {},
    observe?: any
  ): Observable<any> {
    return this.http.get<T>(url, {
      ...this.getOptions(params, headers, AccessToken.DONT_SEND),
      ...(observe ? { observe } : {}),
    });
  }

  /**
   * http POST wrapper method
   * @param url -  the service url
   * @param body - the request body
   * @param formData - true to send body as url params (will send as JSON by default)
   * @param headers - header params to send with request
   */
  public post<T>(
    url: string,
    body: {},
    formData = false,
    headers: any = null
  ): Observable<T> {
    let params: any = null;
    return this.http.post<T>(
      url,
      formData ? this.getHttpParams(body) : body,
      this.getOptions(params, headers)
    );
    // .pipe(
    //   map(res => res),
    //   catchError((error: HttpErrorResponse) => {
    //     if(error.status === 400)
    //       return throwError((error));

    //     return throwError(new AppError(error)););
  }

  /**
   * http PUT wrapper method
   * @param url -  the service url
   * @param params - the params to send with request
   */
  public put<T>(
    url: string,
    params: any,
    sendAsJSON: boolean = true
  ): Observable<T> {
    return this.http.put<T>(
      url,
      sendAsJSON ? params : this.getHttpParams(params),
      this.getOptions()
    );
  }

  /**
   * http PATCH wrapper method
   * @param url -  the service url
   * @param params - the params to send with request
   * @param sendAsJSON - true to send params as JSON (opposite as default)
   */
  public patch<T>(
    url: string,
    params: {},
    sendAsJSON: boolean = false
  ): Observable<T> {
    return this.http.patch<T>(
      url,
      sendAsJSON ? params : this.getHttpParams(params),
      this.getOptions()
    );
  }

  /** getBolb */
  public getBlob(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'arraybuffer',
      ...this.getOptions(),
    });
  }
  /** getBolb */
  public getBlobFiltered(url: string,body:{}): Observable<any> {
    return this.http.post(url,body ,{
      responseType: 'arraybuffer',
      ...this.getOptions(),
    });
  }

  /**
   * http DELETE wrapper method
   * @param url -  the service url
   */
  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url, this.getOptions());
  }

  /**
   * Returns serialized parameters from key/value pairs
   */
  private getHttpParams(params: any) {
    let httpParams = new HttpParams();
    Object?.keys(params)?.forEach((key) => {
      httpParams = httpParams.append(key, params[key]);
    });
    return httpParams;
  }

  /**
   * Returns header parameters from key/value pairs
   */
  private getHttpHeaders(headers: any, headerParams: any) {
    Object?.keys(headerParams)?.forEach((key) => {
      headers = headers.append(key, headerParams[key]);
    });

    return headers;
  }

  /**
   * Return required RequestOptions (the JWT token to header and any required params)
   */
  private getOptions(
    params?: {},
    headerParams?: {},
    attachAuthToken: AccessToken = AccessToken.SEND
  ) {
    const accessToken = this.authTokenService.getAccessToken();
    const requestOptions = {
      headers: {},
      params: params ? this.getHttpParams(params) : {},
    };
    let headers = new HttpHeaders();
    headers = headers.append('Strict-Transport-Security', 'max-age=31536000;includeSubdomains');
    headers = headers.append('document.cookie', 'Secure;HttpOnly');
    headers = headers.append('X-Content-Type-Options', 'nosniff');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('X-XSS-Protection', '1; mode=block');
    headers = headers.append('X-Frame-Options', 'SAMEORIGIN');
    if (accessToken !== null) {
      if (attachAuthToken === AccessToken.SEND) {
        headers = headers.append('Authorization', `Bearer ${accessToken}`);      
      }    
      if (headerParams) {
        headers = this.getHttpHeaders(headers, headerParams);
      }
      requestOptions.headers = headers;
    }
    return requestOptions;
  }
}
