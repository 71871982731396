import { ICommodities } from './commodity.interfaces';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';

export function flatComData(data: ICommodities[]): ICommodities[] {
  let flattedData: ICommodities[] = [];
  data?.forEach((element) => {
    var elem: ICommodities = {
      commId: element.commId,
      commodityName: element.commodityName,
    };
    flattedData.push(elem);
  });
  return flattedData;
}
