import { ActionTypes } from './admin.action';
import { flatManData } from 'src/app/core/Admin/manufacturer/manufacturer.mapper';
import { flatExchangeData } from 'src/app/core/Admin/year-currencies/year-currencies.mapper';
import { flatDisData } from 'src/app/core/Admin/distributor/distributor.mapper';
import { flatManDisData } from 'src/app/core/Admin/manufacturer-distributors/manufacturer-distributors.mapper';
import { flatComData } from 'src/app/core/Admin/commodity/commodity.mapper';
import { flatBuisnessUnitData } from 'src/app/core/Admin/buisness-unit/buisness-unit.mapper';
import { flatCmData } from 'src/app/core/Admin/contract-manu/contract-manu.mapper';
import {
  flatTicketData,
  TicketMapSort,
} from 'src/app/core/Admin/ticket/ticket.mapper';
import { getGridViewHearders } from '../pages/ticket/utils/headers';
import { flatInvitedData } from 'src/app/core/Admin/users/invited-users/invited-users.mapper';
import { flatRegisterdData } from 'src/app/core/Admin/users/registered-users/registered-users.mapper';
import { downLoadFile } from 'src/app/core/utils';
import { UploadAction } from 'src/app/core/upload/upload.interface';
import { normalizedGridColumns, uploadedGridColumns } from '../pages/user/utils/actions';
import { uploadedGridDDL } from '../pages/user/utils/headers';
import { flatCMSiteData } from 'src/app/core/Admin/cm-sites/cm-mapper';
//import { Message } from '@angular/compiler/src/i18n/i18n_ast';
export const UPLOAD_FILE_ID = "upload-file-id";
export let mapedData: any;

export const INITIAL_ADMIN_STATE = {
  ticketType: {
    data: [],
    error: undefined,
    loading: false,
  },
  cmSites: {
    data: [],
    error: undefined,
    loading: false,
  },
  ticketYear: {
    data: [],
    error: undefined,
    loading: false,
  },
  ticketAssignee: {
    data: [],
    error: undefined,
    loading: false,
  },

  approve: {},
  reject: {},
  ticketGridRecord: {
    data: [],
    headers: [],
    error: undefined,
    loading: false,
  },

  ticketGrid: {
    currentPage: 0,
    data: [
      {
        fullName: '',
        year: '',
        ticketStatus: '',
        ticketType: '',
        contractManufacturerName: '',
        insertionDate: '',
        lastUpdateDate: '',
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: undefined,
    sort: {},
    export: {},
    edit: {},
    delete: 0,
    approve: '',
    reject: '',

  },
  logout:{},
  userEmail: {},
  buisnessUnitGrid: {
    currentPage: 0,
    data: [
      {
        businessUnitId: 0,
        businessUnitName: '',
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: 0,
  },
  validationError: {
    error: null,
    isError: false,
  },
  comGrid: [
    {
      currentPage: 0,
      data: [
        {
          commId: 0,
          commodityName: '',
        },
      ],
      offset: 1,
      pageSize: 10,
      total: 0,
      error: undefined,
      loading: false,
      filter: [],
      sort: {},
      export: {},
      edit: {},
      delete: 0,
    },
  ],
  dataGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: 0,
  },
  InvitedGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: 0,
  },
  manGrid: {
    currentPage: 0,
    data: [
      {
        manId: 0,
        manName: '',
        statusId: 0,
        statusName: '',
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    add: {},
    delete: 0,
  },
  resend: {},

  disGrid: {
    currentPage: 0,
    data: [
      {
        distId: 0,
        distName: '',
        distTypeId: 0,
        typeName: '',
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: 0,
    add: {},
  },

  manDisGrid: {
    currentPage: 0,
    data: [
      {
        distId: 0,
        distName: '',
        distTypeId: 0,
        typeName: '',
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: 0,
  },

  yearCurrGrid: {
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    sort: {},
    edit: {},
    delete: 0,
  },
  cmGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: undefined,
    sort: {},
    export: {},
    edit: {},
    delete: 0,
  },
  RegisteredGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: 0,
  },

  commodoties: {},
  distributers: {},
  manufacturers: {},
  manStatus: {},
  companyType: {},
  getCompany: {},
  roleList: {},
  disType: {},
  loaGrid: {
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    sort: {},
    edit: {},
  },
  gridActionPopupData: {
    hasError: undefined,
    loading: false,
    error: undefined,
    message: undefined
  },
  fileStatus: {
    loading: false,
    fileData: null,
  },
  discard: {
    status: undefined,
  },
  currentAction: {
    actionType: UploadAction.UPLOAD,
    fileId: null,
  },
  uploadedFileResult: {
    fileId: 0,
    isActive: '',
  },
  mapUploadedFileResult: {
    data: [],
    columns: [],
    currentPage: 1,
    pageSize: 15,
  },
  normalizationResult: {
    duplicateCount: 0,
    errorCount: 0,
    isNormalized: null,
    normalizedGrid: {
      data: [],
      currentPage: 1,
      pageSize: 15,
    },
  },
  fileSubmited: {
    loading: false,
    error: undefined,
    fileSubmited: false,
  }
};

export function AdminReducer(state = INITIAL_ADMIN_STATE, action: any): any {
  switch (action.type) {
    case ActionTypes.SET_VALIDATION_ERROR: {
      return {
        ...state,
        validationError: {
          ...state.validationError,
          error: action.validationError[0],
          isError: true,
        },
      };
    }
    ///////////load data grid////////////////////////
    case ActionTypes.LOAD_DATA_Grid: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.LOAD_DATA_Grid_SUCCESS: {
      if (action?.payload.data?.length > 0) {
        if (action?.payload?.data[0].cmSiteName) {
          mapedData = flatCMSiteData(action.payload.data);
        }
        else {
          mapedData = action?.payload.data;
        }
      }
      else {
        mapedData = {};
      }
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          data: mapedData,
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.LOAD_DATA_Grid_ERROR: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    // record actions 
    case ActionTypes.RECORD_PAGE_CHANGED: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          currentPage: action.payload,
        },
      };
    }

    case ActionTypes.RECORD_FILTER_CHANGED: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          filter: action?.filter?.filters,
        },
      };
    }

    case ActionTypes.RECORD_SORT_CHANGED: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          sort: action?.sort,
        },
      };
    }

    case ActionTypes.RECORD_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          pageSize: action.payload,
        },
      };
    }


    // delete record 
    case ActionTypes.DELETE_RECORD: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          delete: 'deleted',
          loading: false,
        },
      };
    }

    case ActionTypes.DELETE_RECORD_ERROR: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          loading: false,
          delete: action.payload,
          error: action.payload,
        },
      };
    }
       // logout 
       case ActionTypes.LOG_OUT: {
        return {
          ...state,
          logout:undefined
        };
      }
  
      case ActionTypes.LOG_OUT_SUCCESS: {
        return {
          ...state,
          logout:'success'
        };
      }
  
      case ActionTypes.LOG_OUT_ERROR: {
        return {
          ...state,
          logout:action.payload
        };
      }
    /////////////////////////////////////////////TICKET/////////////////////////////////////////

    //ticket type
    case ActionTypes.GET_TICKET_TYPE: {
      return {
        ...state,
        ticketType: {
          ...state.ticketType,
          loading: true,
        },
      };
    }
    case ActionTypes.GET_TICKET_TYPE_SUCCESS: {
      return {
        ...state,
        ticketType: {
          ...state.ticketType,
          data: action.payload,
          loading: false,
        },
      };
    }
    case ActionTypes.GET_TICKET_TYPE_ERROR: {
      return {
        ...state,
        ticketType: {
          ...state.ticketType,
          loading: false,
          error: action.payload,
        },
      };
    }
  //ticket get CM site site by cm 
  case ActionTypes.GET_CM_SITE_BY_CM: {
    return {
      ...state,
      cmSites: {
        ...state.cmSites,
        loading: true,
      },
    };
  }
  case ActionTypes.GET_CM_SITE_BY_CM_SUCCESS: {
    return {
      ...state,
      cmSites: {
        ...state.cmSites,
        data: action.payload,
        loading: false,
      },
    };
  }
  case ActionTypes.GET_CM_SITE_BY_CM_ERROR: {
    return {
      ...state,
      cmSites: {
        ...state.cmSites,
        loading: false,
        error: action.payload,
      },
    };
  }
    //ticket Assignee
    case ActionTypes.GET_TICKET_ASSIGNEE: {
      return {
        ...state,
        ticketAssignee: {
          ...state.ticketAssignee,
          loading: true,
        },
      };
    }
    case ActionTypes.GET_TICKET_ASSIGNEE_SUCCESS: {
      return {
        ...state,
        ticketAssignee: {
          ...state.ticketAssignee,
          data: action.payload,
          loading: false,
        },
      };
    }
    case ActionTypes.GET_TICKET_ASSIGNEE_ERROR: {
      return {
        ...state,
        ticketAssignee: {
          ...state.ticketAssignee,
          loading: false,
          error: action.payload,
        },
      };
    }

    //ticket year
    case ActionTypes.GET_TICKET_YEAR: {
      return {
        ...state,
        ticketYear: {
          ...state.ticketYear,
          loading: true,
        },
      };
    }
    case ActionTypes.GET_TICKET_YEAR_SUCCESS: {
      return {
        ...state,
        ticketYear: {
          ...state.ticketYear,
          data: action.payload,
          loading: false,
        },
      };
    }
    case ActionTypes.GET_TICKET_YEAR_ERROR: {
      return {
        ...state,
        ticketYear: {
          ...state.ticketYear,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_LOAD_DATA: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.TICKET_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          data: flatTicketData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.TICKET_LOAD_DATA_ERROR: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_LOAD_DATA_RECORD: {
      return {
        ...state,
        ticketGridRecord: {
          ...state.ticketGridRecord,
          loading: true,
        },
      };
    }
    case ActionTypes.TICKET_LOAD_DATA_RECORD_SUCCESS: {
      return {
        ...state,
        ticketGridRecord: {
          ...state.ticketGridRecord,
          data: action.payload,
          headers: getGridViewHearders(action.payload?.columns),
          loading: false,
        },
      };
    }
    case ActionTypes.TICKET_LOAD_DATA_RECORD_ERROR: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_EXPORT_DATA: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.TICKET_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', 'Customer_Ticket.xlsx');
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.TICKET_EXPORT_DATA_ERROR: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_EDIT_RECORD: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.TICKET_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.TICKET_EDIT_RECORD_ERROR: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_DELETE_RECORD: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.TICKET_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          delete: 'deleted',
          loading: false,
        },
      };
    }

    case ActionTypes.TICKET_DELETE_RECORD_ERROR: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: false,
          delete: action.payload,
          error: action.payload,
        },
      };
    }
    //aprove ticket review
    case ActionTypes.TICKET_APPROVE_RECORD: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          approve: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.TICKET_APPROVE_RECORD_SUCCESS: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          approve: 'approved',
          loading: false,
        },
      };
    }

    case ActionTypes.TICKET_APPROVE_RECORD_ERROR: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    //reject ticket review
    case ActionTypes.TICKET_REJECT_RECORD: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          reject: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.TICKET_REJECT_RECORD_SUCCESS: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          reject: 'rejected',
          loading: false,
        },
      };
    }

    case ActionTypes.TICKET_REJECT_RECORD_ERROR: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_PAGE_CHANGED: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          currentPage: action.payload,
        },
      };
    }

    case ActionTypes.TICKET_FILTER_CHANGED: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          filter: action?.filter?.filters,
        },
      };
    }

    case ActionTypes.TICKET_SORT_CHANGED: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          sort: TicketMapSort(action?.payload),
        },
      };
    }

    case ActionTypes.TICKET_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        ticketGrid: {
          ...state.ticketGrid,
          pageSize: action.payload,
        },
      };
    }
    case ActionTypes.EXPORT_COMPONENT_DATA: {
      return {
        ...state,
        export: {
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXPORT_COMPONENT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        export: {
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXPORT_COMPONENT_DATA_ERROR: {
      return {
        ...state,
        export: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXPORT_TICKET_REVIEW: {
      return {
        ...state,
        export: {
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXPORT_TICKET_REVIEW_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        export: {
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXPORT_TICKET_REVIEW_ERROR: {
      return {
        ...state,
        export: {
          loading: false,
          error: action.payload,
        },
      };
    }

    /////////////////////////////////////////////BUISNESS UNIT/////////////////////////////////////////
    case ActionTypes.BUISNESS_UNIT_LOAD_DATA: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.BUISNESS_UNIT_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          data: flatBuisnessUnitData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.BUISNESS_UNIT_LOAD_DATA_ERROR: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_EXPORT_DATA: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'BuisnessUnits.xlsx'
      );
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_EXPORT_DATA_ERROR: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_EDIT_RECORD: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_EDIT_RECORD_ERROR: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_DELETE_RECORD: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_DELETE_RECORD_ERROR: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_PAGE_CHANGED: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          currentPage: action.payload,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_FILTER_CHANGED: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          filter: action?.filter?.filters,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_SORT_CHANGED: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          sort: action?.sort,
        },
      };
    }

    case ActionTypes.BUISNESS_UNIT_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        buisnessUnitGrid: {
          ...state.buisnessUnitGrid,
          pageSize: action.payload,
        },
      };
    }

    /////////////////////////////////////////////COMMODITY/////////////////////////////////////////
    case ActionTypes.COM_LOAD_DATA: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          loading: true,
        },
      };
    }

    case ActionTypes.COM_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          pageSize: action.payload,
        },
      };
    }

    case ActionTypes.COM_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          data: flatComData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.COM_LOAD_DATA_ERROR: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.COM_EXPORT_DATA: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          loading: true,
          error: false,
        },
      };
    }
    case ActionTypes.COM_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'commodities.xlsx'
      );
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.COM_EXPORT_DATA_ERROR: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.COM_EDIT_RECORD: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.COM_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.COM_EDIT_RECORD_ERROR: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.COM_DELETE_RECORD: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.COM_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.COM_DELETE_RECORD_ERROR: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.COM_PAGE_CHANGED: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          currentPage: action.payload,
        },
      };
    }
    case ActionTypes.COM_FILTER_CHANGED: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          filter: action?.filter?.filters,
        },
      };
    }
    case ActionTypes.COM_SORT_CHANGED: {
      return {
        ...state,
        comGrid: {
          ...state.comGrid,
          sort: action?.sort,
        },
      };
    }

    /////////////////////////////////////////////MANUFACTURER/////////////////////////////////////////

    case ActionTypes.MAN_LOAD_DATA: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.MAN_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          data: flatManData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.MAN_LOAD_DATA_ERROR: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.MAN_EXPORT_DATA: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: true,
          error: false,
        },
      };
    }
    case ActionTypes.MAN_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', 'Manufacturers.xlsx');
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_EXPORT_DATA_ERROR: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.MAN_EDIT_RECORD: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_EDIT_RECORD_ERROR: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.MAN_DELETE_RECORD: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_DELETE_RECORD_ERROR: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.MAN_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          pageSize: action.payload,
        },
      };
    }
    case ActionTypes.MAN_PAGE_CHANGED: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          currentPage: action.page,
        },
      };
    }
    case ActionTypes.MAN_FILTER_CHANGED: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          filter: action?.filter?.filters,
        },
      };
    }
    case ActionTypes.MAN_SORT_CHANGED: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          sort: action?.sort,
        },
      };
    }

    ///////////////////////////////////invited////////////////////////////////////////////////////

    case ActionTypes.INVITED_EXPORT_DATA: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          loading: true,
          error: false,
        },
      };
    }
    case ActionTypes.INVITED_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'Invited-Users.xlsx'
      );
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          export: action?.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.INVITED_EXPORT_DATA_ERROR: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          loading: false,
          error: undefined,
        },
      };
    }


    case ActionTypes.INVITED_USERS_LOAD_DATA: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.INVITED_USERS_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          data: flatInvitedData(action.payload?.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.INVITED_USERS_LOAD_DATA_ERROR: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.INVITED_USER_EDIT_RECORD: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.INVITED_USER_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.INVITED_USER_EDIT_RECORD_ERROR: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.INVITED_USER_ADD_RECORD: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          add: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.INVITED_USER_ADD_RECORD_SUCCESS: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          add: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.INVITED_USER_ADD_RECORD_ERROR: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          add: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.INVITED_USER_RESEND_RECORD: {
      return {
        ...state,
        resend: {},
        loading: true,
      };
    }
    case ActionTypes.INVITED_USER_RESEND_RECORD_SUCCESS: {
      return {
        ...state,
        resend: 'resended',
        loading: false,
      };
    }
    case ActionTypes.INVITED_USER_RESEND_RECORD_ERROR: {
      return {
        ...state,
        resend: action.payload,
        loading: false,
        error: action.payload,
      };
    }

    case ActionTypes.GET_COMPANY_BY_TYPENAME: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case ActionTypes.GET_COMPANY_BY_TYPENAME_SUCCESS: {
      return {
        ...state,
        getCompany: action.payload,
        loading: false,
      };
    }

    case ActionTypes.GET_COMPANY_BY_TYPENAME_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case ActionTypes.INVITED_USER_DELETE_RECORD: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.INVITED_USER_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          delete: 'deleted',
          loading: false,
        },
      };
    }

    case ActionTypes.INVITED_USER_DELETE_RECORD_ERROR: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.INVITED_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          pageSize: action.pageSize,
        },
      };
    }
    case ActionTypes.INVITED_PAGE_CHANGED: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          currentPage: action.currentPage,
        },
      };
    }
    case ActionTypes.INVITED_FILTER_CHANGED: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          filter: action?.filter?.filters,
        },
      };
    }
    case ActionTypes.INVITED_SORT_CHANGED: {
      return {
        ...state,
        InvitedGrid: {
          ...state.InvitedGrid,
          sort: action?.sort,
        },
      };
    }

    case ActionTypes.GET_COMPANY_TYPE_List: {
      return {
        ...state,
      };
    }
    case ActionTypes.GET_COMPANY_TYPE_List_SUCCESS: {
      return {
        ...state,
        companyType: action.payload,
      };
    }
    case ActionTypes.GET_COMPANY_TYPE_List_ERROR: {
      return {
        ...state,
      };
    }

    case ActionTypes.GET_ROLE_List: {
      return {
        ...state,
        roleList: []
      };
    }
    case ActionTypes.GET_ROLE_List_SUCCESS: {
      return {
        ...state,
        roleList: action.payload,
      };
    }
    case ActionTypes.GET_ROLE_List_ERROR: {
      return {
        ...state,
      };
    }

    ///////////////////////////////////registered////////////////////////////////////////////////////
    case ActionTypes.REGISTERED_USERS_LOAD_DATA: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.REGISTERED_USERS_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          data: flatRegisterdData(action?.payload?.data),
          currentPage: action?.payload?.currentPage,
          offset: action?.payload?.offset,
          pageSize: action?.payload?.pageSize,
          total: action?.payload?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.REGISTERED_USERS_LOAD_DATA_ERROR: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.REGISTERED_USER_EDIT_RECORD: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.REGISTERED_USER_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          edit: action?.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.REGISTERED_USER_EDIT_RECORD_ERROR: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          edit: action?.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.REGISTERED_EXPORT_DATA: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          loading: true,
          error: false,
        },
      };
    }
    case ActionTypes.REGISTERED_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'Registered-Users.xlsx'
      );
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          export: action?.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.REGISTERED_EXPORT_DATA_ERROR: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.REGISTERED_USER_DELETE_RECORD: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.REGISTERED_USER_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          delete: 'deleted',
          loading: false,
        },
      };
    }

    case ActionTypes.REGISTERED_USER_DELETE_RECORD_ERROR: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          loading: false,
          error: action?.payload,
        },
      };
    }

    case ActionTypes.REGISTERED_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          pageSize: action?.pageSize,
        },
      };
    }
    case ActionTypes.REGISTERED_PAGE_CHANGED: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          currentPage: action?.page,
        },
      };
    }
    case ActionTypes.REGISTERED_FILTER_CHANGED: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          filter: action?.filter.filters,
        },
      };
    }
    case ActionTypes.REGISTERED_SORT_CHANGED: {
      return {
        ...state,
        RegisteredGrid: {
          ...state.RegisteredGrid,
          sort: action?.sort,
        },
      };
    }

    case ActionTypes.GET_USER_EMAIL: {
      return {
        ...state.userEmail,
        loading: true,
      };
    }
    case ActionTypes.GET_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        userEmail: action.payload,
        loading: false,
      };
    }
    case ActionTypes.GET_USER_EMAIL_ERROR: {
      return {
        ...state,
        userEmail: action.payload,
        loading: false,
      };
    }

    /////////////////////////////////////////////DISTRIBUTER/////////////////////////////////////////
    case ActionTypes.DIS_LOAD_DATA: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.DIS_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          data: flatDisData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.DIS_LOAD_DATA_ERROR: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.DIS_PAGE_CHANGED: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          currentPage: action.payload,
        },
      };
    }
    case ActionTypes.DIS_FILTER_CHANGED: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          filter: action?.filter?.filters,
        },
      };
    }
    case ActionTypes.DIS_SORT_CHANGED: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          sort: action?.sort,
        },
      };
    }
    case ActionTypes.DIS_EDIT_RECORD: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.DIS_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.DIS_EDIT_RECORD_ERROR: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.DIS_ADD_RECORD: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          add: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.DIS_ADD_RECORD_SUCCESS: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          add: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.DIS_ADD_RECORD_ERROR: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          add: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.DIS_DELETE_RECORD: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.DIS_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.DIS_DELETE_RECORD_ERROR: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.DIS_EXPORT_DATA: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          loading: true,
          error: false,
        },
      };
    }
    case ActionTypes.DIS_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', 'Distributors.xlsx');
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_EXPORT_DATA_ERROR: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          loading: false,
          error: undefined,
        },
      };
    }
    /////////////////////////////////////////MANUFACTURER_DISTRIBUTER/////////////////////////////////
    case ActionTypes.MAN_DIS_LOAD_DATA: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.MAN_DIS_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          pageSize: action.payload,
        },
      };
    }

    case ActionTypes.MAN_DIS_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          data: flatManDisData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.MAN_DIS_LOAD_DATA_ERROR: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.MAN_DIS_PAGE_CHANGED: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          currentPage: action.payload,
        },
      };
    }
    case ActionTypes.MAN_DIS_FILTER_CHANGED: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          filter: action?.filter?.filters,
        },
      };
    }
    case ActionTypes.MAN_DIS_SORT_CHANGED: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          sort: action?.sort,
        },
      };
    }
    case ActionTypes.MAN_DIS_EDIT_RECORD: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_DIS_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_DIS_EDIT_RECORD_ERROR: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.MAN_DIS_DELETE_RECORD: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_DIS_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_DIS_DELETE_RECORD_ERROR: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.MAN_DIS_EXPORT_DATA: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          loading: true,
          error: false,
        },
      };
    }
    case ActionTypes.MAN_DIS_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'Manufacturers-Distributors-Relationship.xlsx'
      );

      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.MAN_DIS_EXPORT_DATA_ERROR: {
      return {
        ...state,
        manDisGrid: {
          ...state.manDisGrid,
          loading: false,
          error: undefined,
        },
      };
    }

    ///////////////////////////////////////CM//////////////////////////////////////////////////////////

    case ActionTypes.CM_LOAD_DATA: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.CM_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          data: flatCmData(action.payload.data),
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.CM_LOAD_DATA_ERROR: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.CM_EXPORT_DATA: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          loading: true,
          error: false,
        },
      };
    }
    case ActionTypes.CM_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'ContractManufacturer.xlsx'
      );
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.CM_EXPORT_DATA_ERROR: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.CM_EDIT_RECORD: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.CM_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.CM_EDIT_RECORD_ERROR: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.CM_DELETE_RECORD: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.CM_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.CM_DELETE_RECORD_ERROR: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.CM_PAGE_CHANGED: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          currentPage: action.payload,
        },
      };
    }
    case ActionTypes.CM_FILTER_CHANGED: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          filter: action?.filter?.filters,
        },
      };
    }
    case ActionTypes.CM_SORT_CHANGED: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          sort: action?.sort,
        },
      };
    }
    case ActionTypes.CM_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          pageSize: action?.pageSize,
        },
      };
    }

    ///////////////////////////////////////Year-Currency//////////////////////////////////////////////////
    case ActionTypes.YEAR_EDIT_RECORD: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.YEAR_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.YEAR_EDIT_RECORD_ERROR: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.CURRENCY_EDIT_RECORD: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.CURRENCY_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.CURRENCY_EDIT_RECORD_ERROR: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXCHANGE_LOAD_DATA: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.EXCHANGE_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          data: flatExchangeData(action.payload.data),
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.EXCHANGE_LOAD_DATA_ERROR: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXCHANGE_DELETE_RECORD: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXCHANGE_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXCHANGE_DELETE_RECORD_ERROR: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXCHANGE_EDIT_RECORD: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXCHANGE_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.EXCHANGE_EDIT_RECORD_ERROR: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.EXCHANGE_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        yearCurrGrid: {
          ...state.yearCurrGrid,
          pageSize: action.payload,
        },
      };
    }

    ////////////////////////////////// dropdownlists///////////////////////////////////
    case ActionTypes.GET_MAN_List: {
      return {
        ...state,
      };
    }
    case ActionTypes.GET_MAN_List_SUCCESS: {
      return {
        ...state,
        manufacturers: action.payload,
      };
    }
    case ActionTypes.GET_MAN_List_ERROR: {
      return {
        ...state,
      };
    }

    case ActionTypes.GET_DIS_List: {
      return {
        ...state,
      };
    }
    case ActionTypes.GET_DIS_List_SUCCESS: {
      return {
        ...state,
        distributers: action.payload,
      };
    }
    case ActionTypes.GET_DIS_List_ERROR: {
      return {
        ...state,
      };
    }

    case ActionTypes.GET_COMM_List: {
      return {
        ...state,
      };
    }
    case ActionTypes.GET_COMM_List_SUCCESS: {
      return {
        ...state,
        commodoties: action.payload,
      };
    }
    case ActionTypes.GET_COMM_List_ERROR: {
      return {
        ...state,
      };
    }
    case ActionTypes.GET_STATUS_List: {
      return {
        ...state,
      };
    }
    case ActionTypes.GET_STATUS_List_SUCCESS: {
      return {
        ...state,
        manStatus: action.payload,
      };
    }
    case ActionTypes.GET_STATUS_List_ERROR: {
      return {
        ...state,
      };
    }

    case ActionTypes.GET_TYPE_List: {
      return {
        ...state,
      };
    }
    case ActionTypes.GET_TYPE_List_SUCCESS: {
      return {
        ...state,
        disType: action.payload,
      };
    }
    case ActionTypes.GET_TYPE_List_ERROR: {
      return {
        ...state,
      };
    }
    case ActionTypes.TICKET_APPROVE_RECORD: {
      return {
        ...state,
        approve: {
          ...state.approve,
          approve: undefined,
          loading: true,
          error: undefined,
        },
      };
    }

    case ActionTypes.TICKET_APPROVE_RECORD_SUCCESS: {
      return {
        ...state,
        approve: {
          ...state.approve,
          approve: 'approved',
          loading: true,
        },
      };
    }

    case ActionTypes.TICKET_APPROVE_RECORD_ERROR: {
      return {
        ...state,
        approve: {
          ...state.approve,
          loading: false,
          error: action.payload,
        },
      };
    }
    /////////////////////////////loa/////////////////////////////////
    case ActionTypes.LOA_GRID_LOAD_DATA: {
      return {
        ...state,
        loaGrid: {
          ...state?.loaGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.LOA_GRID_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        loaGrid: {
          ...state.loaGrid,
          data: action?.payload?.data?.data,
          currentPage: action?.payload?.data?.currentPage,
          offset: action?.payload?.data?.offset,
          pageSize: action?.payload?.data?.pageSize,
          total: action?.payload?.data?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.LOA_GRID_LOAD_DATA_ERROR: {
      return {
        ...state,
        loaGrid: {
          ...state.loaGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.SHARE_LOA: {
      return {
        ...state,
        gridActionPopupData: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.EMPTY_SHARE_LOA: {
      return {
        ...state,
        gridActionPopupData: {
          message: undefined,
          hasError: undefined,
          loading: undefined,
          error: undefined
        },
      };
    }
    case ActionTypes.SHARE_LOA_SUCCESS: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          message: action.payload,
          hasError: false,
          loading: false
        },
      };
    }
    case ActionTypes.SHARE_LOA_ERROR: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          message: undefined,
          hasError: true,
          loading: false,
          error: action.payload
        },
      };
    }
    case ActionTypes.EXPORT_LOA_PDF: {
      return {
        ...state,
        loaGrid: {
          ...state.loaGrid,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_LOA_PDF_SUCCESS: {
      downLoadFile(action.payload, 'application/pdf', action.fileName);
      return {
        ...state,
        loaGrid: {
          ...state.loaGrid,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_LOA_PDF_ERROR: {
      return {
        ...state,
        loaGrid: {
          ...state.loaGrid,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }
    ///////////////////////////////////////////MASSIVE UPLOAD/////////////////////////////////////////
    case ActionTypes.SET_CURRENT_ACTION_DATA: {
      //Save data in local storage as well to help if user refresh page
      localStorage.setItem(UPLOAD_FILE_ID, action.fileId);
      return {
        ...state,
        currentAction: {
          ...state.currentAction,
          actionType: action.actionType,
          fileId: action.fileId,
          fileType: action.fileType
        },
      };
    }
    case ActionTypes.EMPTY_IS_ACTIVE_FILE_STATUS: {
      return {
        ...state,
        fileStatus: {
          ...state.fileStatus,
          fileData: null,
          loading: false,
        },
        currentAction: {
          ...state.currentAction,
          fileId: null,
        },
      };
    }

    case ActionTypes.CHECK_IS_ACTIVE_FILE: {
      return {
        ...state,
        fileStatus: {
          ...state.fileStatus,
          fileData: null,
          loading: true,
        },
        currentAction: {
          ...state.currentAction,
          fileId: null,
        },
      };
    }

    case ActionTypes.CHECK_IS_ACTIVE_FILE_SUCCESS: {
      return {
        ...state,
        fileStatus: {
          ...state.fileStatus,
          fileData: {
            isActive: action.payload?.isActive,
            fileId: action.payload?.fileId,
          },
          loading: false,
        },
        currentAction: {
          ...state.currentAction,
          fileId: action.payload?.fileId,
        },
      };
    }

    case ActionTypes.CHECK_IS_ACTIVE_FILE_ERROR: {
      return {
        ...state,
        fileStatus: {
          ...state.fileStatus,
          fileData: null,
          loading: false,
        },
      };
    }

    case ActionTypes.DISCARD_ACTIVE_FILE: {
      return {
        ...state,
        discard: {
          ...state.discard,
          loading: true,
        }
      };
    }
    case ActionTypes.DISCARD_ACTIVE_FILE_SUCCESS: {
      return {
        ...state,
        discard: {
          ...state.discard,
          status: 'success',
          loading: false,
        },
      };
    }
    case ActionTypes.DISCARD_ACTIVE_FILE_ERROR: {
      return {
        ...state,
        discard: {
          ...state.discard,
          loading: false,
          error: action.payload,
        }
      };
    }
    case ActionTypes.EMPTY_UPLOAD_FILE: {
      return {
        ...state,
        uploadedFileResult: {
          ...state.uploadedFileResult,
          fileId: null,
          isActive: false,
          error: undefined,
          loading: false,
        },
      };
    }
    case ActionTypes.START_UPLOAD_FILE: {
      return {
        ...state,
        uploadedFileResult: {
          ...state.uploadedFileResult,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.START_UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        uploadedFileResult: {
          ...state.uploadedFileResult,
          fileId: action.payload?.fileId,
          isActive: action.payload?.isActive,
          error: undefined,
          loading: false,
        },
      };
    }
    case ActionTypes.START_UPLOAD_FILE_ERROR: {
      return {
        ...state,
        uploadedFileResult: {
          ...state.uploadedFileResult,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.MAP_UPLOADED_FILE: {
      return {
        ...state,
        mapUploadedFileResult: {
          ...state.mapUploadedFileResult,
          columns: [],
          DDL: [],
          data: [],
          loading: true,
          error: undefined,
        },
        normalizationResult: {
          ...state.normalizationResult,
          isNormalized: null,
        },
      };
    }

    case ActionTypes.MAP_UPLOADED_FILE_SUCCESS: {

      return {
        ...state,
        mapUploadedFileResult: {
          ...state.mapUploadedFileResult,
          columns: uploadedGridColumns(action.payload?.rows[0]?.cells),
          DDL: uploadedGridDDL(action.payload?.columns),
          data: action.payload?.rows,
          valid: action.payload?.valid,
          loading: false,
          error: undefined,
        },
        currentAction: {
          ...state.currentAction,
          fileId: action.payload?.fileId,
        },
        uploadedFileResult: {
          ...state.uploadedFileResult,
          loading: false,
        },
      };
    }
    case ActionTypes.MAP_UPLOADED_FILE_ERROR: {
      return {
        ...state,
        mapUploadedFileResult: {
          ...state.mapUploadedFileResult,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.VALIDATE_UPLOADED_FILE: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          isNormalized: null,
          loading: true,
        },
      };
    }
    case ActionTypes.VALIDATE_UPLOADED_FILE_SUCCESS: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          duplicateCount: action.payload?.duplicateCount,
          errorCount: action.payload?.errorCount,
          loading: false,
          isNormalized: true,
          normalizedGrid: {
            ...state.normalizationResult.normalizedGrid,
            columns: normalizedGridColumns(action.payload?.mappedColumns),
            data: action.payload?.rows,
          },
        },
      };
    }
    case ActionTypes.VALIDATE_UPLOADED_FILE_ERROR: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          loading: false,
          isNormalized: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.SUBMIT_UPLOADED_FILE: {
      return {
        ...state,
        fileSubmited: {
          ...state.fileSubmited,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.SUBMIT_UPLOADED_FILE_SUCCESS: {
      return {
        ...state,
        fileSubmited: {
          ...state.fileSubmited,
          loading: false,
          fileSubmited: true,
        },
      };
    }
    case ActionTypes.SUBMIT_UPLOADED_FILE_ERROR: {
      return {
        ...state,
        fileSubmited: {
          ...state.fileSubmited,
          loading: false,
          error: action.payload,
          fileSubmited: false,
        },
      };
    }

    case ActionTypes.EXPORT_VALIDATED_UPLOADED_FILE: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_VALIDATED_UPLOADED_FILE_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_VALIDATED_UPLOADED_FILE_ERROR: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }
    //ADD OR EDIT RECORD IN GRID
    case ActionTypes.ADD_EDIT_RECORD: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          add: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.ADD_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          add: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.ADD_EDIT_RECORD_ERROR: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          add: undefined,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.EXPORT_CM_SITES: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
           export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_CM_SITES_SUCCESS: {

      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          export: {
          loading: false,
          error: undefined,
          }
        },
      };
    }
    case ActionTypes.EXPORT_CM_SITES_ERROR: {
      return {
        ...state,
        dataGrid: {
          ...state.dataGrid,
          export: {
          loading: false,
          error: action.payload,
          }
        },
      };
    }
    default: {
      return state;
    }
  }
}
