import { UserReducer } from 'src/app/core/user/store/user.reducers';
import { AclReducer } from 'src/app/features/acl/stores/acl.reducer';
import { ReportsReducer } from 'src/app/features/Reports/stores/report.reducer';
import { AdminReducer } from 'src/app/features/admin/stores/admin.reducer';
import { PartsReducer } from 'src/app/features/parts/stores/parts.reducer';
import { UploadReducer } from 'src/app/features/upload/stores/upload.reducer';
import { QuoteReducer } from 'src/app/features/admin/pages/quotes/stores/quotes.reducer';
import { EngineerReducer } from 'src/app/features/engineer/stores/engineer.reducer';

export const reducers = {
  user: UserReducer,
  admin: AdminReducer,
  upload: UploadReducer,
  parts: PartsReducer,
  acl: AclReducer,
  reports: ReportsReducer,
  quote: QuoteReducer,
  engineer: EngineerReducer
};
