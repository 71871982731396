import { ErrorHandler, Injectable } from '@angular/core';  
  
@Injectable()  
export class GlobalExceptionhandelor implements ErrorHandler {  
    constructor() {   
        
    }  
    handleError(error:any) {
        console.log(error);
    }  
  }