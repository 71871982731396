import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogOut, SetValidationError } from 'src/app/features/admin/stores/admin.action';
import { IAppState } from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private router: Router, private store: Store<IAppState>) {}

  logout() {
    this.store.dispatch(new SetValidationError(''));
    localStorage.clear();
    this.store.dispatch(new LogOut());
    this.router.navigateByUrl('/login');
  }
}
