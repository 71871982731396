import { Action } from '@ngrx/store';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export enum ActionTypes {
  /////////////////////////////////////////////////ACL PART /////////////////////////////////////////////////
  PART_LOAD_DATA = '[ACL] PART_LOAD_DATA',
  PART_LOAD_DATA_SUCCESS = '[ACL] PART_LOAD_DATA_SUCCESS',
  PART_LOAD_DATA_ERROR = '[ACL] PART_LOAD_DATA_ERROR',

  PART_PAGE_CHANGED = '[ACL] PART_PAGE_CHANGED',
  PART_PAGE_SIZE_CHANGED = '[ACL] PART_PAGE_SIZE_CHANGED',
  PART_SORT_CHANGED = '[ACL] PART_SORT_CHANGED',
  PART_FILTER_CHANGED = '[ACL] PART_FILTER_CHANGED',
  UPDATE_GRID_COLUMNS = '[ACL] UPDATE_GRID_COLUMNS',

  PART_EXPORT_DATA = '[ACL] PART_EXPORT_DATA',
  PART_EXPORT_DATA_SUCCESS = '[ACL] PART_EXPORT_DATA_SUCCESS',
  PART_EXPORT_DATA_ERROR = '[ACL] PART_EXPORT_DATA_ERROR',

  BU_LOAD_DATA = '[ACL] BU_LOAD_DATA',
  BU_LOAD_DATA_SUCCESS = '[ACL] BU_LOAD_DATA_SUCCESS',
  BU_LOAD_DATA_ERROR = '[ACL] BU_LOAD_DATA_ERROR',
}

//////////////////////////////////////////Part ////////////////////////////////

export class PartLoadData implements Action {
  readonly type = ActionTypes.PART_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: any[],
    public selectedFilter?: any[],
    public sort?: SortDescriptor[]
    ) {}
}

export class PartPageChanged implements Action {
  readonly type = ActionTypes.PART_PAGE_CHANGED;
  constructor(public page?: number) {
    }
}
export class PartFilterChanged implements Action {
  
  readonly type = ActionTypes.PART_FILTER_CHANGED;
  constructor(public filter?: any) {
   
  }
}
export class PartPageSizeChanged implements Action {
  readonly type = ActionTypes.PART_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}
export class UpdateGridColumns implements Action {
  
  readonly type = ActionTypes.UPDATE_GRID_COLUMNS;
  constructor(public payload?: any[]) {
  }
}
export class PartSortChanged implements Action {
  readonly type = ActionTypes.PART_SORT_CHANGED;
  constructor(public sort?: SortDescriptor) {}
}
export class PartLoadDataSuccess implements Action {
  readonly type = ActionTypes.PART_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class PartLoadDataError implements Action {
  readonly type = ActionTypes.PART_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}
export class PartExportGrid implements Action {
  readonly type = ActionTypes.PART_EXPORT_DATA;
  constructor(public fileName?: any,
    public page?: number,
    public size?: number,
    public filter?: any[],
    public sort?: SortDescriptor[]) {}
}

export class PartExportGridSucees implements Action {
  readonly type = ActionTypes.PART_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer,public fileName: any) {}
}

export class PartExportGridError implements Action {
  readonly type = ActionTypes.PART_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}
