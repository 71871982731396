import { IBusinessUnit } from './buisness-unit.interfaces';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';

export function flatBuisnessUnitData(data: IBusinessUnit[]): IBusinessUnit[] {
  let flattedData: IBusinessUnit[] = [];
  data?.forEach((element) => {
    var elem: IBusinessUnit = {
      businessUnitId: element.businessUnitId,
      businessUnitName: element.businessUnitName,
    };
    flattedData.push(elem);
  });
  return flattedData;
}
