import {
  IFilterModel,
  IGridCustomRequest,
  IGridRequest,
  ISortModel,
} from 'src/app/shared/shared.interface';

export function gridRequestAlertDetails(
  page?: number,
  size?: number,
  sortByModel?: ISortModel,
  filterModel?: IFilterModel[],
  parentId?:number,
  selectedIds?:number[]
) {
  const request: IGridCustomRequest = {
    filterModel: filterModel ? filterModel : [],
    selectedIds: selectedIds ? selectedIds : [],
    parentId: parentId ? parentId : 0,
    page: page ? page : 0,
    size: size ? size : 10,
    sortByModel: sortByModel
      ? sortByModel
      : { columnName: 'currentManufacturer', direction: 'asc' },
  };

  return request;
}
