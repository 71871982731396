import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopoverComponent } from '@progress/kendo-angular-tooltip';
import { Subscription } from 'rxjs';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';
import { PopoverContainerComponent } from 'src/app/shared/components/popover/popover.component';
import { actionComponentData, IAppState } from 'src/app/shared/shared.interface';
import { PartDetailsRecordLoadData } from '../../stores/parts.action';
import { ComponentsPartDataDetailsSelector } from '../../stores/parts.selector';
@Component({
  selector: 'app-part-details',
  templateUrl: './part-details.component.html',
  styleUrls: ['./part-details.component.scss']
})
export class PartDetailshComponent implements OnInit {
  public popover: PopoverComponent;
  PartDataDetails = new Map<string, string>();
  priceHistoryDetails = new Map<string, string>();
  lifeCycleRiskSummaryDetails = new Map<string, string>();
  selectedData: any = {};
  public subscribtion = new Subscription();
  isLoading: boolean;
  partDataDetails: any = {};
  MPN: string = '';
  Manufacturer: string = '';
  Description: string = '';

  constructor(private cdr: ChangeDetectorRef, private store: Store<IAppState>,
    public dataIn: ProviderClass) {
    this.selectedData = this.dataIn;
    this.store.dispatch(new PartDetailsRecordLoadData(this.selectedData.data.record_id, this.selectedData?.yearId))
  }

  ngOnInit(): void {
    this.startSubscribtion();

  }
  startSubscribtion() {
    this.subscribtion.add(this.subscribeToListSelector());

  }
  subscribeToListSelector() {
    return this.store.select(ComponentsPartDataDetailsSelector).subscribe((data) => {
      this.isLoading = data?.loading ? data?.loading : false;
      if (data.lifeCycleRiskSummaryDetails != undefined) {

        var lifeCycleRiskSummaryDetailskeys = Object.getOwnPropertyNames(data.lifeCycleRiskSummaryDetails);
        lifeCycleRiskSummaryDetailskeys.forEach((element: any) => {
          this.lifeCycleRiskSummaryDetails.set(element, data.lifeCycleRiskSummaryDetails[element])
        });
      }
      if (data.partDataDetails != undefined) {
        var partDataDetailskeys = Object.getOwnPropertyNames(data.partDataDetails);
        partDataDetailskeys.forEach((element: any) => {
          this.PartDataDetails.set(element, data.partDataDetails[element]);
          if(element==='Manufacturer PN'){
            this.MPN=data.partDataDetails[element];
          }
          else if(element==='Current Manufacturer'){
            this.Manufacturer=data.partDataDetails[element];
          }
          else if(element==='Description'){
            this.Description=data.partDataDetails[element];
          }
        });
      
      }
      if (data.priceHistoryDetails != undefined) {
        var priceHistoryDetailskeys = Object.getOwnPropertyNames(data.priceHistoryDetails);
        priceHistoryDetailskeys.forEach((element: any) => {
          this.priceHistoryDetails.set(element, data.priceHistoryDetails[element])
        });
      }
      
    });
  }
  propertyOf(name: keyof string) {
 
    return name;
  }
}