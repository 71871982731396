import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { LoggedOutComponent } from './logged-out/logged-out.component';
 import { MainMenuComponent } from './components/main-menu/main-menu.component';
 
@NgModule({
  declarations: [
    LoggedInComponent,
    HeaderComponent,
     LoggedOutComponent,
    MainMenuComponent,
  
  ],
  imports: [CommonModule, RouterModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {}
