import { ApiService } from '../../api/api.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ComponentResponse } from './components.interfaces';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComponentsService {
  selectedYear: string = '';

  constructor(private apiService: ApiService,
    private store: Store,
    private _http: HttpClient) {
  }

  public getComponentsList(yearId?:number, page: number = 0, size?: number) {
    return this.apiService.get<ComponentResponse>(
      `${environment.baseUrls.serviceURL}/cmt/PartsComponent/report/${yearId}`); //&pageNumber=${page}&pageSize=${size}
  }

  public exportList() {
    return this._http.get(`${environment.baseUrls.serviceURL}/exportClassicComponents?partNumber=${this.selectedYear}`, {
      responseType: 'arraybuffer',
    });
  }
  public LoadComponent(comId: string) {
    return this._http.get(`${environment.baseUrls.serviceURL}/exportClassicComponents?partNumber=${this.selectedYear}`, {
      responseType: 'arraybuffer',
    });
  }
}
