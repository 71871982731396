import { IReviewTicketColumn } from 'src/app/core/Admin/ticket/ticket.interfaces';
import {
  actionMenu,
  ColumnType,
  GridHeaderData,
  TicketType,
} from 'src/app/shared/shared.interface';
import { TicketReviewComponent } from '../pages/ticket-review/ticket-review.component';

export const headers: GridHeaderData[] = [
  {
    field: 'Assignee',
    title: 'Assignee',
    filterable:true,
    sortable :true,
    filterColName:'Assignee'
  },
  {
    field: 'Requester',
    title: 'Requester',
    filterable:true,
    sortable :true,
    filterColName: 'Requester'
  },
  {
    field: 'Year',
    title: 'Year',
    filterable:true,
    sortable :true,
    filterColName: 'Year',

  },
  {
    field: 'TicketStatus',
    title: 'Ticket Status',
    filterable:true,
    sortable :true,
    filterColName: 'TicketStatus'
  },
  {
    field: 'TicketType',
    title: 'Ticket Type',
    filterable:true,
    sortable :true,
    filterColName: 'TicketType'

  },
  {
    field: 'ContractManufacturerName',
    title: 'CM',
    filterable:true,
    sortable :true,
    filterColName: 'ContractManufacturerName'
  },
  {
    field: 'cmSiteName',
    title: 'CM Site Name ',
    filterable:true,
    sortable :true,
    filterColName: 'cmSiteName'
  },
  {
    field: 'country',
    title: 'Country',
    filterable:true,
    sortable :true,
    filterColName: 'country'
  },
  {
    field: 'address',
    title: 'Address',
    filterable:true,
    sortable :true,
    filterColName: 'address'
  },
  {
    field: 'InsertionDate',
    title: 'Created',
    type: ColumnType.DATE,
    filterable:true,
    sortable :true,
    isDate:true,
    filterColName: 'InsertionDate',

  },
  {
    field: 'LastUpdateDate',
    title: 'Last Updated',
    type: ColumnType.DATE,
    filterable:true,
    sortable :true,
    isDate:true,
    filterColName:'LastUpdateDate'
  },
  {
    field: 'Actions',
    title: 'Actions',
    getCellActions: (data: any, column: any) => {
      if (data.TicketStatus === TicketType.PREVIEW) {
        return [
          {
            componentData: {
              componentTilte: 'Ticket Review',
              componentName: TicketReviewComponent,
              open: true,
            },
            actionType: actionMenu.view,
          },
        ];
      } else if (data.TicketStatus === TicketType.NEW) {
        return [
          {
            componentData: {},
            actionType: actionMenu.delete,
          },
        ];
      } else if (
        data.TicketStatus === TicketType.CLOSED ||
        data.TicketStatus === TicketType.REJECTED
      ) {
        return [
          {
            componentData: {},
            actionType: actionMenu.download,
          },
        ];
      }
    },
  },
];

export function getGridViewHearders(
  dynamicColumns: IReviewTicketColumn[] = []
): GridHeaderData[] {
  return [
    ...dynamicColumns?.map((c: any) => {
      return {
        field: c.featureName,
        title: c.featureTitle,
        filterable: false,
        sortable: false,
      };
    }),
  ];
}
