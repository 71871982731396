import { IPartSearch } from './part-search.interfaces';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';

export function flatPartSearchData(data: IPartSearch[]): IPartSearch[] {
  let flattedData: IPartSearch[] = [];
  data?.forEach((element) => {
    var elem: IPartSearch = {
      //ComID: element.ComID,
      PartNumber: element.PartNumber,
      Manufacturer: element.Manufacturer,
      PlName: element.PlName,
      Description: element.Description,
      Datasheet: element.Datasheet,
      RoHS: element.RoHS,
      RoHSVersion: element.RoHSVersion,
      TaxonomyPath: element.TaxonomyPath,
    } as IPartSearch;
    flattedData.push(elem);
  });
  return flattedData;
}
