import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { IFilterModel } from 'src/app/shared/shared.interface';
import { IPart } from './part.interfaces';

export function flatPartData(data: IPart[]): IPart[] {
  let flattedData: IPart[] = [];
  data?.forEach((element) => {
    var elem: IPart = {
      wabtec_acl_id: element.wabtec_acl_id,
      cpn: element.cpn,
      mpn: element.mpn,
      man: element.man,
      businessUnit: element.businessUnit,
      wabtecDescription: element.wabtecDescription,
      seMpn: element.seMpn,
      seMan: element.seMan,
      seDescription: element.seDescription,
    };
    flattedData.push(elem);
  });
  return flattedData;
}

export function partMapSort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {
    case 'businessUnit':
      return { columnName: 'businessUnit.businessUnitName', direction: dir };
    default:
      return { columnName: kendoSort?.field, direction: dir };
  }
}

export function partMapFilter(gridFilters: any, selectedFilters?: any) {
  const filterArr: IFilterModel[] = [];
  let allfilters: any = [];
  if (gridFilters?.length > 0) {
    allfilters.push(gridFilters);
  }
  if (selectedFilters?.length > 0) {
    allfilters.push(selectedFilters);
  }
  var filterModel: IFilterModel;
  var kendoFilter;
  var kendoFilters;
  allfilters.forEach((filterValue:any) => {
    if (filterValue?.length > 0) {
      filterValue.forEach((item: any, index: number) => {
        kendoFilter = _.isArray(filterValue) ? filterValue[index] : filterValue;
        kendoFilters = _.isArray(kendoFilter?.filters)
          ? kendoFilter?.filters[0]
          : kendoFilter;
        const filterObject: IFilterModel = {
          columnName: kendoFilters?.field,
          value: kendoFilters?.value,
        };
        filterArr.push(filterObject);
  
      });
    }
  });
  
  return filterArr;

}
