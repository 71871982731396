import {
  actionMenu,
  ColumnType,
  GridHeaderData,
} from 'src/app/shared/shared.interface';

export function uploadedGridDDL(features: any = []): GridHeaderData[] {
  return [
    {
      field: 'UnMapped',
      title: 'UnMapped',
      required: false,
      index: -1,
    },
    ...features?.map((c: any) => {
      return {
        index: c.index,
        mappedIndexes: c.index != null ? [c.index] : [],
        field: c.featureId,
        title: c.featureName,
        required: c.required,
      };
    }),
  ];
}

//return selectedColumn.find((c: any) => c.index === columnIndex - 1);
export function uploadedGridColumns(
  dynamicColumns: any = []
): GridHeaderData[] {
  return [
    {
      field: 'row',
      title: 'Row',
      filterable: false,
      sortable: false,
      reorderable: false,
      dropdown: false,
      getCellValue: (data: any) => {
        return data?.rowIndex + 1;
      },
    },
    ...dynamicColumns?.map((c: any) => {
      return {
        field: `col${c.columnIndex}`,
        title: c.value,
        filterable: false,
        sortable: false,
        reorderable: false,
        dropdown: true,
        getCellValue: (data: any, column: any, columnIndex: any) => {
          if (data && data.cells && columnIndex) {
            return data.cells.find(
              (c: any) => c.columnIndex === columnIndex - 1
            )?.value;
          }
        },
        getSelectedValue: (data: any, columnIndex: any) => {
          if (data && columnIndex) {
            const value = data.find((c: any) => c.index === columnIndex - 1)
              ? data.find((c: any) => c.index === columnIndex - 1)
              : data.find((c: any) => c.index === -1);
            return value;
          }
        },
      };
    }),
  ];
}

export function normalizedGridColumns(
  dynamicColumns: any = []
): GridHeaderData[] {
  return [
    {
      field: 'row',
      title: 'Row',
      filterable: false,
      sortable: false,
      reorderable: false,
      dropdown: false,
      required: false,
      getCellValue: (data: any) => { 
        return data?.rowIndex;
      },
    },
    ...dynamicColumns?.map((c: any) => { 
      return {
        ...c,
        field: `col${c.featureId}`,
        title: c.featureName,
        filterable: false,
        sortable: false,
        reorderable: false,
        dropdown: false,
        required: c.required,
        getCellValue: (data: any, column: any, columnIndex: any) => {
          if (data && data.cells && columnIndex) {
            return data.cells[columnIndex - 1]?.value;
          }
        },
      };
    }),
  ];
}

export function uploadedSuppliereGridColumns(
  dynamicColumns: any = []
): GridHeaderData[] {
  return [
    {
      field: 'row',
      title: 'Row',
      filterable: false,
      sortable: false,
      reorderable: false,
      dropdown: false,
      getCellValue: (data: any) => {
        return data?.rowIndex + 1;
      },
    },
    ...dynamicColumns?.map((c: any) => {
      return {
        field: `col${c.columnIndex}`,
        title: c.value,
        filterable: false,
        sortable: false,
        reorderable: false,
        dropdown: true,
        getCellValue: (data: any, column: any, columnIndex: any) => {
           
          if (data && data.cells && columnIndex) {
            return data.cells.find(
              (c: any) => c.columnIndex === columnIndex - 1
            )?.value;
          }
        },
        getSelectedValue: (data: any, columnIndex: any) => {
          if (data && columnIndex) {
            const value = data.find((c: any) => c.index === columnIndex - 1)
              ? data.find((c: any) => c.index === columnIndex - 1)
              : data.find((c: any) => c.index === -1);
            return value;
          }
        },
      };
    }),
  ];
}
export function uploadedSupplierGridDDL(features: any = []): GridHeaderData[] {
  return [
    {
      field: 'UnMapped',
      title: 'UnMapped',
      required: false,
      index: -1,
    },
    ...features?.map((c: any) => {
      return {
        index: c.index,
        mappedIndexes: c.index != null ? [c.index] : [],
        field: c.columnId,
        title: c.featureName,
        required: c?.required,
      };
    }),
  ];
}
