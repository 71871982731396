import { ComponentActions, GridHeaderData } from 'src/app/shared/shared.interface';
import {
  InvitedFilterChanged,
  InvitedPageChanged,
  InvitedPageSizeChanged,
  InvitedSortChanged,
  InvitedUsersLoadData,
  RegisteredFilterChanged,
  RegisteredLoadData,
  RegisteredPageChanged,
  RegisteredPageSizeChanged,
  RegisteredSortChanged,
} from '../../../stores/admin.action';

export const gridActions: ComponentActions = {
  loadAction: new InvitedUsersLoadData(),
  changePageAction: new InvitedPageChanged(),
  sortAction: new InvitedSortChanged(),
  searchAction: new InvitedFilterChanged(),
  changePageSizeAction: new InvitedPageSizeChanged(),
};

export const registeredGridActions: ComponentActions = {
  loadAction: new RegisteredLoadData(),
  changePageAction: new RegisteredPageChanged(),
  sortAction: new RegisteredSortChanged(),
  searchAction: new RegisteredFilterChanged(),
  changePageSizeAction: new RegisteredPageSizeChanged(),
};


export function uploadedGridColumns(
  dynamicColumns: any = []
): GridHeaderData[] {
  return [
    {
      field: 'row',
      title: 'Row',
      filterable: false,
      sortable: false,
      reorderable: false,
      dropdown: false,
      getCellValue: (data: any) => {
        return data?.rowIndex + 1;
      },
    },
    ...dynamicColumns?.map((c: any) => {
      return {
        field: `col${c.columnIndex}`,
        title: c.value,
        filterable: false,
        sortable: false,
        reorderable: false,
        dropdown: true,
        getCellValue: (data: any, column: any, columnIndex: any) => {
          if (data && data.cells && columnIndex) {
            return data.cells.find(
              (c: any) => c.columnIndex === columnIndex - 1
            )?.value;
          }
        },
        getSelectedValue: (data: any, columnIndex: any) => {
          if (data && columnIndex) {
            const value = data.find((c: any) => c.index === columnIndex - 1)
              ? data.find((c: any) => c.index === columnIndex - 1)
              : data.find((c: any) => c.index === -1);
            return value;
          }
        },
      };
    }),
  ];
}

export function normalizedGridColumns(
  dynamicColumns: any = []
): GridHeaderData[] {
  return [
    {
      field: 'row',
      title: 'Row',
      filterable: false,
      sortable: false,
      reorderable: false,
      dropdown: false,
      required: false,
      getCellValue: (data: any) => { 
        return data?.rowIndex;
      },
    },
    ...dynamicColumns?.map((c: any) => { 
      return {
        ...c,
        field: `col${c.featureId}`,
        title: c.featureName,
        filterable: false,
        sortable: false,
        reorderable: false,
        dropdown: false,
        required: c.required,
        getCellValue: (data: any, column: any, columnIndex: any) => {
          if (data && data.cells && columnIndex) {
            return data.cells[columnIndex - 1]?.value;
          }
        },
      };
    }),
  ];
}
