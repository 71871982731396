<div class="main-menu">
    <ul class="d-flex list-unStyle">
    <li *ngFor='let item of menuItems'>
         
        <a *ngIf="item.className" [routerLink] = "'/'+item.routerLink" [routerLinkActive]="['active-link']"
         [queryParams]="{display: 'grid'}">{{item.name}}</a>
        

        <a *ngIf="!item.className" [routerLink]="'/'+item.routerLink" [routerLinkActive]="['active-link']">{{item.name}}</a>
    </li>
    </ul>
</div>