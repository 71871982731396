import { HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { IAdminState } from '../core/Admin/admin.interfaces';
 import { IUserState } from '../core/user/user.interface';

export interface IAppState {
  user: IUserState;
  admin: IAdminState;
 }

export interface IListingState<ItemType> extends IApiRequestState {
  data: Array<ItemType>;
  total?: number;
  currentPage: number;
  pageSize: number;
  offset?: number;
  filter?: any;
  sort?: any;
  edit?: any;
  delete?: any;
  active?: any;
  add?: any;
  approve?: any;
  reject?: any;
  columns?: Array<GridHeaderData>;
  export?: IApiRequestState;
}
// for the width of the grid columns
export enum ColumnWidth {
  InitialWidth = 1,
}

export interface IApiRequestState {
  loading?: boolean;
  error?: HttpErrorResponse;
}

export interface IPaginationState {
  offset: number;
  limit: number;
  total?: number;
}
export interface IFilterObject{
  columnName:string;
  value:string;
}
export interface ComponentActions {
  loadAction?: any;
  changePageAction?: any;
  sortAction?: any;
  searchAction?: any;
  changePageSizeAction?: any;
  updateColumnsAction?:any;
  exportAction?: any;
}

export interface IGridRequest {
  page: number;
  size: number;
  filterModel?: IFilterModel[];
  sortByModel?: ISortModel;
}
export interface IGridCustomRequest {
  page: number;
  size: number;
  filterModel?: IFilterModel[];
  parentId?:number;
  selectedIds?:number[];
  sortByModel?: ISortModel;
}
export class IGridQuoteDetailsRequest implements IGridRequest {
  page: number;
  size: number;
  filterModel?: IFilterModel[] | undefined;
  sortByModel?: ISortModel | undefined;
  quoteId?:number;

}
export interface IFilterModel {
  columnName?: string;
  value?: string;
}

export interface ISortModel {
  columnName?: string;
  direction?: string;
}

export interface ISortKendoModel {
  field: string;
  dir: string;
}

export interface IGridResponse<item> {
  data: Array<item>;
  total: number;
  currentPage: number;
  pageSize: number;
  offset: number;
}

export enum ColumnType {
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  DATE = 'DATE',
  LINK = 'LINK',
  LinkNotClickable = 'LinkNotClickable',
  LINKWITHNAVIGATIONROUTE = 'LinkWithNavigationRoute'
}

export enum TicketType {
  NEW = 'New',
  PREVIEW = 'Review',
  CLOSED = 'Closed',
  REJECTED = 'Rejected',
}

export interface GridHeaderData {
  title: string;
  field: string;
  isDate?:boolean;
  isDateTime?:boolean;
  dropdown?: boolean;
  componentName?:any;
  isbool?:boolean;
  type?: ColumnType;
  filterable?: boolean;
  sortable?: boolean;
  reorderable?: boolean;
  hidden?: boolean;
  filterColName?: string;
  actions?: actions[];
  key?: string;
  className?: string;
  tooltip?: string;
  index?: number;
  required?: boolean;
  getCellValue?(
    data?: any,
    column?: any,
    columnIndex?: any,
    rowIndex?: any
  ): any;
  getCellActions?(data?: any, column?: any): any;
  getSelectedValue?(data?: any, columnIndex?: any): any;
  locked?: boolean;
  lockable?: boolean;
}

export interface actions {
  actionType: actionMenu;
  componentData?: actionComponentData;
  tooltip?: string;
  isDisabled?: boolean;
}

export interface navBarItems {
  itemType: ItemType;
  buttonType?: any;
  message: string;
  icon?: any;
  action?: any;
  themeColor?:any;
  openedComponentAction?: any;
  hasPopOver?: any;
  popOverContent?: any;
  showOption?: any;
  dropdownData?: any;
  position?: any;
  htmlData?: any;
  showdialog?: any;
  openedComponent?: any;
  disable?: any;
}

export enum ItemType {
  BUTTON = 'BUTTON',
  STEPPER = 'STEPPER',
 }
export enum ButtonType {
  BUTTON = 'button',
  SPLITBUTTON = 'splitButton',
  DROPDOWNBUTTON = 'buttonDropdown',
}

export interface actionComponentData {
  open?: boolean;
  componentTilte?: string;
  componentName?: any;
  data?: any;
}

export enum actionMenu {
  edit = 'k-i-edit',
  delete = 'k-i-delete',
  download = 'k-i-download',
  upload = 'k-i-upload',
  view = 'k-i-eye',
  user='k-i-user',
  resend='k-i-email',
  customDownload ='download',
  customUpload ='upload',
  archive='archive',
  unarchive='unarchive'
}

export interface menuItem {
  name: string;
  routerLink: string;
  permissions?: string[];
  hidden?: boolean;
  queryParams?: Params;
  className?: any
  queryParamsHandling?: any;
}

export enum DialogType {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export interface buttonInfo {
  value: string;
  color?: string;
  click?(): void;
  closeDialogAfterClick?: boolean;
}
