<!-- 
<div class="k-loading-panel" [ngClass]="{'inside-modal':loadingPanel,'hasOverlay':hasOverlay}">
      <div class="k-loading-panel-mask"></div>
      <div class="k-loading-panel-wrapper">
      <kendo-loader
      [type]="type"
      [themeColor]="themeColor"
      [size]="size"
      >
      </kendo-loader>
</div> -->
<div class="k-loading-panel" [ngClass]="{'inside-modal':loadingPanel,'hasOverlay':hasOverlay}">

<div class="hm-spinner"></div>
</div>