import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadComponent } from 'src/app/features/parts/stores/parts.action';
import { actionComponentData, IAppState } from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-view-component',
  templateUrl: './view-component.component.html',
  styleUrls: ['./view-component.component.scss']
})
export class ViewComponentComponent implements OnInit {
  selectedData: actionComponentData = {};

  constructor(private store: Store<IAppState>) { }

  ngOnInit(): void {
    //this.store.dispatch(new LoadComponent(this.selectedData.data.comId));
  }

}
