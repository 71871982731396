import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DataStateChangeEvent,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  process,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  actions,
  ColumnType,
  ColumnWidth,
  GridHeaderData,
  IAppState,
  IFilterModel,
} from 'src/app/shared/shared.interface';
import { DialogShareService } from '../../Modals/dialog.service';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridComponent implements OnInit, OnChanges {
  @Input() rowData: any;
  @Input() SelectedFilter: any;
  @Input() selectedrows: number[];
  @Input() selectedby: string;
  @Input() actions: any | undefined;
  @Input() allowColumnFilter: boolean = true;
  public selectRow: boolean = false;
  @Input() headers: GridHeaderData[] = [];
  @Input() filterable: any = 'menu';
  @Input() pageSize = 10;
  @Input() currentPage = 0;
  selectedCheckbox: any;
  @Input() AddCheckBoxCoulmn: boolean = false;
  @Input() columnsetting: boolean = false;
  @Input() selectable: any;
  @Input() hideheader: boolean;
  @Output() applyCheckBox = new EventEmitter<any>();
  @Output() getSelectedRow = new EventEmitter<any>();
  @Output() getAllSelectedRow = new EventEmitter<any>();
  @Output() getUnSelectedRow = new EventEmitter<any>();
  @Output() showSelectedRow = new EventEmitter<any>();
  @Output() DisableSelectedRow = new EventEmitter<any>();
  @Output() passData = new EventEmitter<any>();
  @Output() getSelectedHeader = new EventEmitter<any>();
  @Output() navigateToLink = new EventEmitter<any>();
  @Output() getSelectedRows = new EventEmitter<any>();
  @Output() getChangedRows = new EventEmitter<any>();
  @Output() onSelectAllClick = new EventEmitter<any>();
  @Input() selcetdeHeaders: any;
  @Input() disabledRows: any;
  @Input() hidePager: boolean = false;
  @Input() dynamicHeader: boolean = false;
  @Input() width: number = 200;
  @Input() height: string = 'height: 75vh';
  @Input() hasSelectAllBtn: boolean = true;

  public hasHiddenColumns: boolean = false;

  public offset?: number;
  public sort: SortDescriptor[] = [];
  public gridHeaders: GridHeaderData[];
  public dropdownItems: GridHeaderData[] = [];
  public gridFilters?: any = { logic: 'and', filters: [] };
  public searchText: IFilterModel[] = [];
  public isFilterRequest: boolean = false;
  public selectedRows: any;
  public DisableSelectedRows: any;

  public allowUnsort = false;
  public buttonCount = 10;
  public cleardata: any[];
  public info = true;
  public pageSizes = [10, 20, 50];
  public previousNext = true;
  public position = 'bottom';
  public currentColumnIndex: number = 0;
  public ColumnType: typeof ColumnType = ColumnType;
  public selectedItems: number[] = [];
  // public width: number = 200;
  public mySelection: number[] = [];
 
  public ColumnWidth: typeof ColumnWidth = ColumnWidth;
  filterData: any[] = [];
  isExist: boolean = false;
  allfilterData: any[] = [];
  previousFilterData: any[] = [];
  newFilterData: any[] = [];
  clearedFilterdata: any;
  lastSelectedFilter: any;
  filteredData: any[] = [];
  constructor(
    private store: Store<IAppState>,
    private service: DialogShareService
  ) {
  }

  ngOnInit(): void {
    this.sort = this.rowData?.sort;
    this.hasHiddenColumns = this.gridHeaders?.some((c) => c.hidden === true);
   
    this.initFilterObject();
    this.loadGridData();
  }

  public columnVisibilityChange(event: any): void {
    event?.columns?.forEach((element: any) => {
      const column = this.gridHeaders.find((c) => c.field === element.field);
      if (column) {
        column.hidden = element.hidden;
      }
    });

    this.hasHiddenColumns = this.gridHeaders?.some((c) => c.hidden === true);
    this.updateStoreGridColumns();
  }
  public updateStoreGridColumns() {
    if (this.actions && this.actions.updateColumnsAction) {
      this.actions.updateColumnsAction.payload = this.gridHeaders;
      this.store.dispatch(this.actions.updateColumnsAction);
    }
  }

  public isDisabled = (row: any) => {
 
    return { 'k-disabled': row.dataItem[this.disabledRows] === 'true' };
  };
  selectionChangeHandler(event: any) {
    this.getChangedRows.emit(event);
    this.getSelectedRows.emit(this.selectedrows);
    this.selectedItems = [];
    if (event.selectedRows.length > 0) {
      this.selectedItems.push(event.selectedRows);
    } else if (event.deselectedRows.length > 0) {
      this.getUnSelectedRow.emit(event.deselectedRows);

      if (this.selectedrows.length > 0) {
        this.selectedrows.forEach((deselectItem: any) => {
          this.selectedItems = this.selectedrows.filter(
            (o) => o !== event.deselectedRows[0].index
          );
        });
      }
      event.deselectedRows.forEach((deselectItem: any) => {
        this.selectedItems = this.selectedItems.filter(
          (o) => o !== deselectItem.dataItem.alertPartListId
        );
      });
    }
    if (event.deselectedRows.length == 0) {
      this.getSelectedRow.emit(event.selectedRows[0]);
      this.getAllSelectedRow.emit(this.selectedItems[0]);
    }

    // if the ctrlKey or shiftKey are true then selectAll button is clicked
    if(event.ctrlKey && event.shiftKey && this.selectedrows?.length > 1){
      // Select all
      this.onSelectAllClick.emit(true);
    }else if(event.ctrlKey && event.shiftKey && this.selectedrows?.length == 0){
      // unselect all
      this.onSelectAllClick.emit(false);
    }
  }

  passLinkData() {
    this.passData.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.gridHeaders =
      this.headers && this.headers?.length > 0
        ? this.headers
        : this.rowData?.columns;
    this.dropdownItems = this.rowData?.DDL;
    if(this.dynamicHeader){
      if(this.gridHeaders?.length>0){
        this.initFilterObject();
      }
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.offset = event.skip;
    this.pageSize = event.take;
    this.currentPage = (this.offset + this.pageSize) / this.pageSize - 1;

    if (this.actions && this.actions.changePageAction) {
      this.actions.changePageAction.page = this.currentPage;
      this.store.dispatch(this.actions.changePageAction);
    }
    if (this.actions && this.actions.changePageSizeAction) {
      this.actions.changePageSizeAction.pageSize = this.pageSize;
      this.store.dispatch(this.actions.changePageSizeAction);
    }

    //reload gird data based on selected page
    this.loadGridData();
  }

  public filterChange(event: any): void { 
    
    this.newFilterData = [];
    this.filteredData = [];
    this.clearedFilterdata=[];
    event.filters.map((f: CompositeFilterDescriptor) => {
      f.filters.find((filter: any) => {
        let isExist = this.newFilterData.filter(filter => filter == filter.field);
        if (isExist.length === 0) {
          this.newFilterData.push(filter.field);
          this.gridFilters.filters.filter((f: any) => {
            f.filters.filter((newfilter: any) => {
              newfilter != f.filters
            })
          })
        }
         this.clearedFilterdata = this.previousFilterData.filter(x => !this.newFilterData.includes(x));
      }
      ) as CompositeFilterDescriptor
    })
    if(this.newFilterData?.length==0 && this.previousFilterData.length>0){
      this.clearedFilterdata=this.previousFilterData[0];
    }
    if (this.clearedFilterdata != '') {
      this.removeAllFiltersexceptActive(this.clearedFilterdata);
    }
    if (!event.filters.length) {
      this.searchText[this.currentColumnIndex].value = '';
    }
     this.gridFilters = {
      filters: event.filters,
      logic: event.logic,
    };
    this.storeFilterAndLoadData();

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state?.filter) {
      this.gridFilters = state?.filter;
      //this.storeFilterAndLoadData();
    }
  }

  private storeFilterAndLoadData() {
    this.previousFilterData = [];
    if (this.actions?.searchAction) {
      this.actions.searchAction.filter = this.gridFilters;
      this.gridFilters.filters.map((f: CompositeFilterDescriptor) => {
        f.filters.find((f: any) => {
          let isExist = this.previousFilterData.filter(filter => filter == f.field);
          if (isExist.length === 0) {
            this.previousFilterData.push(f.field);
          }
        }
        ) as CompositeFilterDescriptor
      })
      this.store.dispatch(this.actions?.searchAction);
      this.loadGridData(true);
    }
  }
  public removeAllFiltersexceptActive(columnName: any) {
    this.gridHeaders?.forEach((column, i) => {
      if (column.filterColName == columnName) this.searchText[i].value = '';
    });
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (this.actions && this.actions.sortAction) {
      this.actions.sortAction.sort = this.sort;
      this.store.dispatch(this.actions.sortAction);
    } else {
      this.rowData = process(this.rowData.data, { sort: sort });
    }
    //reload gird data based on required sort
    this.loadGridData();
  }
  loadGridData(isFilterRequest: boolean = false): void {

    if (this.actions && this.actions.loadAction) {
      // this.offset = this.currentPage? this.currentPage : this.offset;
      this.actions.loadAction.filter = this.gridFilters.filters;
      if (this.SelectedFilter?.filters?.length > 0) {
        this.actions.loadAction.selectedFilter = this.SelectedFilter.filters;
      }
      else{
        this.actions.loadAction.selectedFilter=[];
      }

      if (!isFilterRequest) {
        this.actions.loadAction.page = this.currentPage;
      } else {
        this.actions.loadAction.page = 0;
        this.offset = 1;
      }
      this.actions.loadAction.size = this.pageSize;
      this.actions.loadAction.sort = this.sort;
      setTimeout(() => this.store.dispatch(this.actions.loadAction)); // to solve fast filter change :D
    }
  }

  public edit(action: actions, data: any): void {
    if (action.componentData) action.componentData.data = data;
    this.service.allowOpenDialog(action);
  }

  public checkBoxAction(event: any, data: any) {
    data.isLocked = event.target.checked ? 1 : 0;
    this.applyCheckBox.emit(data);
  }

  public selectValueChange($event: any, columnIndex: number) {
    this.getSelectedHeader.emit({
      index: columnIndex,
      selectedColumn: $event,
    });
  }

  public searchTextChange(
    columnName: any,
    filter: FilterService,
    index: number
  ) {
     this.currentColumnIndex = index;
    filter.filter({
      filters: [
        {
          field: columnName,
          operator: 'contains',
          value: this.searchText[index]?.value?.trim(),
        },
      ],
      logic: 'or',
    });
  }

  public initFilterObject() {
     this.gridHeaders?.forEach((column) => {
      
      this.searchText.push({ columnName: column.field, value: '' });
    });
  }



  public getCellValue(data: any, column: any, columnIndex: any, rowIndex: any) {
     
    const gridColumn = this.gridHeaders.find((c) => c.field === column?.field);
    if (gridColumn && typeof gridColumn.getCellValue !== 'undefined') {
      return gridColumn.getCellValue(data, column, columnIndex, rowIndex);
    } else {
      return data[column.field];
    }
  }

  public getCellActions(headerActions: any, data: any, column: any) {
    const gridColumn = this.gridHeaders.find((c) => c.field === column?.field);
    if (gridColumn && typeof gridColumn.getCellActions !== 'undefined') {
      return gridColumn.getCellActions(data, column);
    } else {
      return headerActions;
    }
  }

  public getSelectedValue(data: any, column: any, columnIndex: any) {
    const gridColumn = this.gridHeaders.find((c) => c.field === column?.field);
    if (gridColumn && typeof gridColumn.getSelectedValue !== 'undefined') {
      return gridColumn.getSelectedValue(data, columnIndex);
    } else {
      return data.find((c: any) => c.index === -1);
    }
  }

  public navigateToItemLink(dataItem: any) {
    this.navigateToLink.emit(dataItem);
  }
}
