import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Role } from 'src/app/shared/models/role';
import { IUser } from './user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public getUser(): Observable<IUser> {
    return of({
      email: '',
      firstName: '',
      lastName: '',
      personName: '',
      role:[Role.Admin]
    });
  }
}
