import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent implements OnChanges {
  @Input() data: any;
  @Input() defaultItem: any;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() valuePrimitive: boolean;
  @Input() isLocked: boolean = false;

  @Input() filterable: boolean;
  @Input() selectedItem: any;
  @Output() filterChange = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() open = new EventEmitter<string>();
  enableLocked: boolean = false;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data?.length <= 1) {
      this.defaultItem = this.data[0];
      this.data = [];
    } else {
      this.defaultItem = {
        [this.valueField]: 0,
        [this.textField]: 'Select Value',
      };
    }
  }

  handleFilter(event: string) {
    this.filterChange.emit(event);
  }

  public itemDisabled = (itemArgs: { dataItem: any; index: number }) => {
    if (this.isLocked) {
      return itemArgs.dataItem.isLocked;
    }
  };
  handleSelectionChange($value: any) {
    this.selectionChange.emit($value);
  }
  handleValueChange($value: any) {
    this.valueChange.emit($value);
  }

}
