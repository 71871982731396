<kendo-popover #popover [callout]="callOut" (show)="onShow()" (shown)="onShown()" (hide)="onHide()"
  (hidden)="onHidden()" [width]="width" [height]="height" [position]="position">
  <ng-template *ngIf="showdialog" kendoPopoverBodyTemplate let-anchor let-data="htmlData">

    <ng-container
    *ngComponentOutlet="openedComponent"
  ></ng-container>

  </ng-template>
  <ng-template #descriptionTemplate kendoPopoverBodyTemplate let-anchor let-data="description">
    <div class="description">
      {{description}}
    </div>
  </ng-template>
</kendo-popover>