import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  ICompanyType,
  IRoleList,
  LoginStatus,
  LoginStatusChecked,
} from 'src/app/core/Admin/users/invited-users/invited-users.interfaces';
import {
  GetCMSiteName,
  GetCompanyByTypeName,
  GetCompanyTypeList,
  GetRoleList,
  RegisteredLoadData,
  RegisteredUserEdit,
  SetValidationError,
} from 'src/app/features/admin/stores/admin.action';
import {
  CompanySelector,
  CompanyTypeSelector,
  GetCmSiteSelector,
  GetValidationErrorSelector,
  RegisteredGridDataSelector,
  RegisteredGridEditSelector,
  RegisteredselectedFiltersSelector,
  RoleSelector,
  selectedFiltersSelector,
} from 'src/app/features/admin/stores/admin.selector';
import { CustomValidators } from 'src/app/shared/classes/custom-validator';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';
import { DialogShareService } from 'src/app/shared/components/Modals/dialog.service';
import { Dialog } from 'src/app/shared/models/model.interface';
import {
  actionComponentData,
  IAppState,
} from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-edit-registered',
  templateUrl: './edit-registered.component.html',
  styleUrls: ['./edit-registered.component.scss'],
})
export class EditRegisteredComponent implements OnInit, OnDestroy {
  public CompanyTypeId: any;
  public CompanyType: string;
  public CompanyTypeList: any;
  public CompanyList: any[] = [];
  saveData: boolean = false;
  public RolelistItems: IRoleList[] = [];
  public CompanyTypes: ICompanyType[] = [];

  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  region: string = '';
  title: string = '';
  companyName: any;
  defaultcmSite: any = {"cmSiteName": "Select Value", "cmSiteId": null};
  companyTypeId: any;
  role: number=0;
  //public loginStatus: LoginStatus;
  checkedStatusEnable: boolean = false;
  checkedStatusDisable: boolean = false;
  companyType: string = '';

  // firstNameError: string = '';
  // lastNameError: string = '';
  emailError: string = '';
  phoneError: string = '';
  // regionError: string = '';
  // titleError: string = '';
  companyError: string = '';
  companyTypeError: string = '';
  roleError: string = '';
  cmSiteError: string = '';

  loginStatus: string;
  loginStatusId: number;
  error: any;
  IsError: boolean = false;
  subscription: Subscription = new Subscription();
  CmSiteDisabled: boolean = true;
  userId: number = 0;
  roleName: string = '';
  roleId: any;
  companyId: any;
  name: string;

  public gridFilters = {
    filter: undefined,
    sort: undefined,
    page: 0,
    pageSize: 10,
  };
  selectedData: actionComponentData = {};
  edit: boolean = false;
  checkedEnabledvalue: number = LoginStatusChecked.ENABLED;
  checkedDisabledvalue: number = LoginStatusChecked.DISABLED;
  IsDisabled: boolean=false;
  cmSite: any;
  cmSiteListItemsSource: any;
  cmSiteListItems: any;
  registeredUserForm: FormGroup;

  constructor(private fb: FormBuilder,
    public dataIn: ProviderClass,
    private dialogService: DialogShareService,
    private store: Store<IAppState>
  ) {
    this.registeredUserForm = this.fb.group({
      firstName:['', [CustomValidators.cannotContainSpace,
        CustomValidators.cannotStartWithWhiteSpaceValidator,
        CustomValidators.cannotEndWithWhiteSpaceValidator,
        CustomValidators.cannotBeSpecialCharsOnlyValidator,
        CustomValidators.cannotBeNumbersOnlyValidator,
        Validators.minLength(1),
        Validators.maxLength(128)]],
      lastName: ['', [CustomValidators.cannotContainSpace,
      CustomValidators.cannotStartWithWhiteSpaceValidator,
      CustomValidators.cannotEndWithWhiteSpaceValidator,
      CustomValidators.cannotBeSpecialCharsOnlyValidator,
      CustomValidators.cannotBeNumbersOnlyValidator,
      Validators.minLength(1),
      Validators.maxLength(128)]],
      region: ['', [CustomValidators.cannotContainSpace,
      CustomValidators.cannotStartWithWhiteSpaceValidator,
      CustomValidators.cannotEndWithWhiteSpaceValidator,
      CustomValidators.cannotBeSpecialCharsOnlyValidator,
      CustomValidators.cannotBeNumbersOnlyValidator,
      Validators.minLength(1),
      Validators.maxLength(128)]],
      title: ['', [CustomValidators.cannotContainSpace,
      CustomValidators.cannotStartWithWhiteSpaceValidator,
      CustomValidators.cannotEndWithWhiteSpaceValidator,
      CustomValidators.cannotBeSpecialCharsOnlyValidator,
      CustomValidators.cannotBeNumbersOnlyValidator,
      Validators.minLength(1),
      Validators.maxLength(128)]],
      phone: [''],
      email: [''],
     companyName:['',Validators.required],
     cmSite:[''],
    });
    this.loadData();
  }
  public CompanyTypeplaceHolder: ICompanyType = {
    companyTypeId: 0,
    companyTypeName: 'Select Company Type',
  };
  public RoleplaceHolder: IRoleList = {
    roleId: 0,
    roleName: 'Select Role',
  };
  ngOnInit(): void {
    this.selectedData = this.dataIn;
    if (this.selectedData?.data) {
      this.getEditData();
       this.edit = true;
    }
    this.startSubscribtion();
  }

  hasError(ctrName:string,errorName:string):boolean{
    let res=this.registeredUserForm?.controls[ctrName]?.hasError(errorName);
    return res;
   }

  startSubscribtion() {
    this.subscription.add(this.subscribeToDialog());
    this.subscription.add(this.subscribeCompanyTypeListItems());
    this.subscription.add(this.subscribeRoleListItems());
    this.subscription.add(this.subscribeCompanyListItems());
    this.subscription.add(this.subscribeToFilterSelector());
    this.subscription.add(this.subscribeToAddEditSelector());
    this.subscription.add(this.subscribeToCMSiteSelector());
  }
  subscribeToFilterSelector() {
    return this.store
      .select(RegisteredselectedFiltersSelector)
      .subscribe((data) => {
        if ((data: any) => data) {
          this.gridFilters.filter = data.filter;
          this.gridFilters.sort = data.sort;
          this.gridFilters.pageSize = data.pageSize;
          this.gridFilters.page = data.page;
        }
      });
  }
  getSelectedCompanyTypeItem($event: any) {
    this.companyId = $event.companyTypeId;
    this.CompanyList = [];
    this.companyName = '';
 
    this.companyType = $event.companyTypeName;
    this.role=0;
    if(this.companyId== 1){
      this.companyName=this.companyType;
      this.registeredUserForm.controls['companyName'].setValue(this.companyType);

      this.IsDisabled=true;
      this.cmSite = '';
      this.CmSiteDisabled = true;
    }
    else {
      if (this.companyType === 'Contract Manufacturer') {
        this.CmSiteDisabled = false;
        this.IsDisabled = false;

      }
      else {
        this.IsDisabled = false;
        this.CmSiteDisabled = true;
        this.cmSite = '';
      }
    }
    this.store.dispatch(new GetRoleList(this.companyType));
  }
  getSelectedRole($event: any) {
    this.role = $event.roleId;
  }
  subscribeToCMSiteSelector() {
    this.store.select(GetCmSiteSelector).subscribe((response) => {

      if (response.data?.length) {
        this.cmSiteListItemsSource = response.data;
        this.cmSiteListItemsSource = this.cmSiteListItemsSource.sort((a: any, b: any) =>
          a.cmSiteName.localeCompare(b.cmSiteName)
        );
        this.cmSiteListItems = this.cmSiteListItemsSource.slice();
      }
    });
  }
  getEditData() {
      (this.firstName = this.selectedData?.data?.firstName),
      (this.lastName = this.selectedData?.data?.lastName),
      (this.email = this.selectedData?.data?.email),
      (this.phone = this.selectedData?.data?.phone),
      (this.title = this.selectedData?.data?.title),
      (this.userId = this.selectedData?.data?.userId),
      (this.role = this.selectedData?.data?.role?.roleId),
      (this.companyId = this.selectedData?.data?.companyType?.companyTypeId),
      (this.companyName =this.selectedData?.data?.companyName),
     // (this.cmSite = {cmSiteName: this.selectedData?.data?.contractManufacturerSite?.cmSiteName,cmSiteId: this.selectedData?.data?.contractManufacturerSite?.cmSiteId});
      this.region = this.selectedData?.data?.region;


    this.registeredUserForm.controls['companyName'].setValue(this.selectedData?.data?.companyName);
    this.registeredUserForm.controls['cmSite'].setValue(this.selectedData?.data?.contractManufacturerSite?.cmSiteName);





    if (this.selectedData.data.companyTypeName === 'Contract Manufacturer' && this.companyId) {
      
      this.store.dispatch(new GetCMSiteName(this.selectedData.data.companyId));

    }
    if(this.companyName==='wabtec'){
      this.IsDisabled=true;
    }
    else if (this.selectedData.data.companyTypeName === 'Contract Manufacturer') {
      this.CmSiteDisabled = false;

    }
    this.loginStatus = this.selectedData?.data?.status;
    if (this.loginStatus === LoginStatus.ENABLED) {
      this.checkedStatusEnable = true;
      this.loginStatusId = LoginStatusChecked.ENABLED;
    } else if (this.loginStatus === LoginStatus.DISABLED) {
      this.checkedStatusDisable = true;
      this.loginStatusId = LoginStatusChecked.DISABLED;
    }
    this.store.dispatch(new GetRoleList(this.selectedData?.data?.companyType?.companyTypeName));
    
  }
  companyChange(value: any): void {
    this.store.dispatch(new GetCMSiteName(value.id));
 }
  handleFilterCMsite(value: string) {

    this.cmSiteListItems = this.cmSiteListItemsSource.filter(
      (s: any) =>
        s.cmSiteName
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  loadData() {
    this.store.dispatch(new GetCompanyTypeList());
  }
  subscribeToAddEditSelector() {
    this.store.dispatch(new SetValidationError(''));

    return this.store
      .select(RegisteredGridEditSelector)
      .subscribe((edit: any) => {
        if (edit?.userId && this.saveData) {
          this.saveData = false;
          this.store.dispatch(
            new RegisteredLoadData(
              this.gridFilters.page,
              this.gridFilters.pageSize,
              this.gridFilters?.filter,
              this.gridFilters.sort
            )
          );
          this.dialogService.sendMessage(Dialog.close);
        } else if (this.saveData) {
          this.store.select(GetValidationErrorSelector).subscribe((error) => {
            if (error.error != undefined) {
              this.error = error.error;
              this.IsError = error.isError;
            }
          });
        }
      });
  }
  subscribeCompanyTypeListItems() {
    return this.store.select(CompanyTypeSelector).subscribe((res) => {
      if (res?.length) {
        this.CompanyTypes = res;
        this.CompanyTypes = this.CompanyTypes.slice();
      }
    });
  }
  subscribeRoleListItems() {
    return this.store.select(RoleSelector).subscribe((res) => {
      if (res?.length===1) {
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
        this.role=res[0].roleId;
      }
      else if(res?.length>1){
        
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
      }
    });
    

  }
  click(value: any) {
    if (value.length >= 2) {
      return this.store.dispatch(
        new GetCompanyByTypeName(this.companyId, value)
      );
    } else {
      this.CompanyList = [];
    }
  }

  subscribeCompanyListItems() {
    return this.store.select(CompanySelector).subscribe((res) => {
      if (res?.length) {
        this.CompanyList = res;
      }
    });
  }
  public valueChange(value: string): void {
    return this.store.dispatch(new GetCompanyByTypeName(this.companyId, value));
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToDialog() {
    return this.dialogService.getMessage().subscribe((data) => {
      if (data.res == Dialog.submit) {
        this.selectedData = {};
        this.save();
      }
    });
  }
  getCheckedValue(event: any) {
    this.loginStatusId = event.target.value;
  }
  save() {
    if (this.validate()) {
      this.saveData = true;
      if (this.edit) {
        
        this.store.dispatch(
          new RegisteredUserEdit({
            userId: this.userId,
            firstName: this.firstName,
            lastName: this.lastName,
            fullName: this.selectedData?.data?.fullName,
            email: this.email,
            phone: this.phone,
            region: this.region,
            companyTypeId: this.companyId,
           // companyName: this.companyName.id?this.companyName.name:this.companyName,
            companyName:this.registeredUserForm.controls['companyName'].value?.id?this.registeredUserForm.controls['companyName'].value?.name:this.registeredUserForm.controls['companyName'].value,
            title: this.title,
            roleId: this.role,
            userStatusId: this.loginStatusId,
            cmSiteName:this.registeredUserForm.controls['cmSite'].value?.cmSiteName?this.registeredUserForm.controls['cmSite'].value?.cmSiteName:this.registeredUserForm.controls['cmSite'].value?this.registeredUserForm.controls['cmSite'].value:'',
          })
        );
      }
    }
  }

  validate() {
    let validate = true;
    this.emailError = '';
    this.companyTypeError = '';
    this.companyError = '';
    this.roleError = '';
    this.cmSiteError = '';
    if (this.companyId === null || this.companyId === 0) {
      this.companyTypeError = 'Company Type is required';
      validate = false;
    }
    this.companyName=this.registeredUserForm.controls['companyName'].value?.id?this.registeredUserForm.controls['companyName'].value?.name:this.registeredUserForm.controls['companyName'].value;
    if (this.companyName === '') {
      this.companyError = 'Company Name is required';
      validate = false;
    } else if (this.companyName?.length < 2) {
      this.companyError = 'Company Name lenght should be 3 character at least';
      validate = false;
    }
    if (this.role === 0) {
      this.roleError = 'Role Name is required';
      validate = false;
    }
    this.cmSite =this.registeredUserForm.controls['cmSite'].value;
    if (this.companyType === 'Contract Manufacturer' && this.companyName && !this.cmSite) {
      this.cmSiteError = 'CM Site is required';
      validate = false;
    }
    if (this.email === '') {
      this.emailError = 'Email is required';
      validate = false;
    }
    // if (this.firstName === '') {
    //   this.firstNameError = 'First Name is required';
    //   validate = false;
    // }
    // if (this.lastName === '') {
    //   this.lastNameError = 'Last Name is required';
    //   validate = false;
    // }
    // if (this.title === '') {
    //   this.titleError = 'Tilte is required';
    //   validate = false;
    // }
    // if (this.region === '') {
    //   this.regionError = 'Region is required';
    //   validate = false;
    // }
    if (this.phone === '') {
      this.phoneError = 'Phone is required';
      validate = false;
    }
    return this.registeredUserForm?.valid && validate;
  }
}
