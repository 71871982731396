export enum Role {
    User = 'User',
    Parts = 'Parts',
    ACL = 'ACL',
    Upload = 'Upload',
    Reports = 'Reports',
    Admin = 'Admin',
    Enginner = 'Engineer',
    Info = 'Info',

  
}
export enum SubRoleAdmin {
    Tickets = 'Tickets',
    ContractManufacturer='Contract Manudfacturer',
    BusinessUnits='Business Units',
    Commodities='Commodities',
    Manufacturers='Manufacturers',
    Distributers='Distributers',
    ManufacturerDistributers='Manufacturer Distributers',
    Years='Years',
    Users='Users',
    Quotes = 'Quotes',

}

export enum SubRoleInfo {
    ContractManufacturerHelp='Contract Manufacturer Help',
    SupplierInfo='Supplier Info',
    CommodityMangerHelp='Commodity Manger Help',
    AdminHelp='Admin Help',
    Terms='Terms',
    Policies='Policies',

}
export enum SubRoleParts {
    Dashboard = 'Dashboard',
    CBOM='CBOM',
    Component='Components',
    PartSearch='Part Search',
    CrossSearch='Cross Search',
    Alerts='Alert',
}
export enum SubRoleAcl {
    parts = 'Parts',
}
export enum SubRoleUpload {
    Requests = 'Requests',
    History = 'History',
    Quotes = 'UploadQuotes',
    ArchivedQuotes = 'ArchivedQuotes',
    Awards = 'Awards',
    LOAs='LOAs',
    UpdateExisting='Update Existing'
}
export enum SubRoleReports {
    Reports = 'Reports',
    ReportsTable = 'Reports Table',
    ReportsGraph = 'Reports Graph',

}