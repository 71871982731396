import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  ExportComponentData,
  TicketLoadDataRecord,
} from 'src/app/features/admin/stores/admin.action';
import { TicketGridDataRecordSelector } from 'src/app/features/admin/stores/admin.selector';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';
import {
  actionComponentData,
  GridHeaderData,
  IAppState,
  ItemType,
} from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-ticket-review',
  templateUrl: './ticket-review.component.html',
  styleUrls: ['./ticket-review.component.scss'],
})
export class TicketReviewComponent implements OnInit {
  public navBarButtons: any;
  public isLoading: boolean = false;
  selectedData: actionComponentData = {};
  public headers: GridHeaderData[] = [];
  public gridData: any;
  public subscribtion = new Subscription();
  public action: any;
  public height: string = 'height: 30vh';

  constructor(private store: Store<IAppState>, public dataIn: ProviderClass) {
  }

  ngOnInit(): void {
    this.selectedData = this.dataIn;
    this.startSubscribtion();

    this.adjustNavBar();
  }

  startSubscribtion() {
    this.subscribtion.add(this.subscribeToListSelector());
    this.subscribtion.add(this.subscribeToList());
  }
  subscribeToList() {
    if (this.selectedData.data !== undefined) {
      this.store.dispatch(
        new TicketLoadDataRecord(this.selectedData.data.TicketId)
      );
    }
  }
  subscribeToListSelector() {
    return this.store
      ?.select(TicketGridDataRecordSelector)
      .subscribe((data) => {
        this.isLoading = data.loading ? data.loading : false;
        if (data.data.length != 0) {
          if (data?.data.assembly !== null) {
            data.data.assembly.forEach((asm: any) => {
              let headerasm = data.headers.find(
                (obj: any) => obj.field === asm.asmName);
              if (headerasm != undefined) {
                data.headers.some((element: any, idx: any) => {
                  if (element.title === headerasm.title) {

                    if (!element.title?.includes('ASM')) {
                      data.headers[idx].title = headerasm.title + ' | ASM:' + asm.total + '';
                    }
                  }
                });
                // let element =data.headers.find((obj: any,index:any) => obj.field===headerasm.field);
                // data.headers[]  headerasm.field+'|'+asm.total;
                // elem.field=headerasm+'|'+asm.total;
              }
            })
          }
        }
        this.headers = data.headers;
        this.gridData = data.data;
      });
  }
  adjustNavBar() {
    // navbar  convert export to arrow function to solve this issue
    let exportGrid = () => {
      this.store.dispatch(
        new ExportComponentData(
          this.selectedData.data.TicketId,
          'all',
          'Ticket_File.xlsx'
        )
      );
    };

    this.navBarButtons = [
      {
        itemType: ItemType.BUTTON,
        message: 'Export',
        icon: 'download',
        action: exportGrid,
      },
    ];
  }
}
