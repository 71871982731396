import { ApiService } from '../../../core/api/api.service';
import { environment } from '../../../../../src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppState, IGridResponse } from 'src/app/shared/shared.interface';
import { Store } from '@ngrx/store';
import { currentActionSelector } from 'src/app/features/upload/stores/upload.selector';
import { MappedDataRequest } from '../upload.request';

@Injectable({
  providedIn: 'root',
})
export class ServiceMiddleware {
  public BaseURL: string = `${environment.baseUrls.serviceURL}`;
  public requestData: object = {};
  fileId:any;
  constructor(private apiService: ApiService, private _http: HttpClient,
    private store: Store<IAppState>) {
    this.store.select(currentActionSelector).subscribe((data) => {
      this.fileId = data.fileId;
    });
  }

  public getList(ApiUrl: string, model: object) {
    return this.apiService.post(this.BaseURL + ApiUrl, model);
  }
  public Get(ApiUrl: string) {
    return this.apiService.get(this.BaseURL + ApiUrl);
  }
  public AddRecord(ApiUrl: string, model: object) {
    return this.apiService.post(this.BaseURL + ApiUrl, model);
  }
  public EditRecord(ApiUrl: string, model: object) {
    return this.apiService.post(this.BaseURL + ApiUrl, model);
  }

  public GetSingleRecord(ApiUrl: string) {
    return this.apiService.get(this.BaseURL + ApiUrl);
  }
  public sendMappedColumnsData(mappedColumns: any, start: any,ApiUrl: string,ticketId?:string) {
    const request = MappedDataRequest(mappedColumns, start, this.fileId,ticketId);
    return this.apiService.post(this.BaseURL + ApiUrl, request);

  }
  public DeleteRecord(ApiUrl: string) {
    return this.apiService.delete(this.BaseURL + ApiUrl);
  }
  public exportList(ApiUrl: string) {
    return this.apiService.getBlob(this.BaseURL + ApiUrl);
  }
  public exportFilteredList(ApiUrl: string, model: object) {
    return this.apiService.getBlobFiltered(this.BaseURL + ApiUrl, model);
  }
}
