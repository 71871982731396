import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import {
  IManuDisFlatObject,
  IManufacturerDistributors,
} from './manufacturer-distributors.interface';

import { IFilterModel } from '../../../shared/shared.interface';


export function flatManDisData(
  data: IManufacturerDistributors[]
): IManuDisFlatObject[] {
  let flattedData: IManuDisFlatObject[] = [];
  data?.forEach((element) => {
    var elem: IManuDisFlatObject = {
      distId: element?.distributor?.distId,
      distName: element?.distributor?.distName,
      commId: element?.commodity?.commId,
      commodityName: element?.commodity?.commodityName,
      distTypeId: element?.distributor?.distributorType?.distTypeId,
      manDistId: element?.manDistId,
      manId: element?.manufacturer?.manId,
      manName: element?.manufacturer?.manName,
      statusId: element?.manufacturer?.manufacturerStatus?.statusId,
      statusName: element?.manufacturer?.manufacturerStatus?.statusName,
      typeName: element?.distributor?.distributorType?.typeName,
    };
    flattedData.push(elem);
  });
  return flattedData;
}

export function mapKendoSortToAPISort(sort?: SortDescriptor[]) {
    
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {
    case 'distName':
      return { columnName: 'distributor.distName', direction: dir };
    case 'commodityName':
      return { columnName: 'commodity.commodityName', direction: dir };
    default:
      return { columnName: 'manufacturer.manName', direction: dir };
  }
}

export function mapKendoFilterToAPI(gridFilters: any) {
   
   const filterArr: IFilterModel[] = [];
  var filterModel: IFilterModel;
  var kendoFilter;
  var kendoFilters;
  gridFilters.forEach((item: any, index: number) => {
    kendoFilter = _.isArray(gridFilters) ? gridFilters[index] : gridFilters;
    kendoFilters = _.isArray(kendoFilter?.filters)
      ? kendoFilter?.filters[0]
      : kendoFilter;
      const filterObject: IFilterModel = {
        columnName: kendoFilters?.field,
        value: kendoFilters?.value,
      
      };
      if(filterObject.columnName==='manName')
      {
        filterObject.columnName='manufacturer.manName';
      }
      else if(filterObject.columnName==='distName'){
        filterObject.columnName='distributor.distName';
      }
      else if(filterObject.columnName==='commodityName'){
        filterObject.columnName='commodity.commodityName';
      }
      filterArr.push(filterObject);
    
  });
  return filterArr;


  // switch (kendoFilters?.field) {

  //   case 'manName':
  //     return [{
  //       columnName: 'manufacturer.manName',
  //       value: kendoFilters?.value,
  //     }];
  //   case 'distName':
  //     return [{
  //       columnName: 'distributor.distName',
  //       value: kendoFilters?.value,
  //     }];
  //   case 'commodityName':
  //     return [{
  //       columnName: 'commodity.commodityName',
  //       value: kendoFilters?.value,
  //     }];
  //   default:
  //     return [];
  // }
}
