import { Action } from '@ngrx/store';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IFilterModel, ISortModel } from 'src/app/shared/shared.interface';

export enum ActionTypes {
  /////////////////////////////////////////////////PART SEARCH/////////////////////////////////////////////////
  PART_SEARCH_UPDATE_SEARCH_TEXT = 'PART_SEARCH_UPDATE_SEARCH_TEXT',
  PART_SEARCH_LOAD_DATA = 'PART_SEARCH_LOAD_DATA',
  PART_SEARCH_LOAD_DATA_SUCCESS = 'PART_SEARCH_LOAD_DATA_SUCCESS',
  PART_SEARCH_LOAD_DATA_ERROR = 'PART_SEARCH_LOAD_DATA_ERROR',

  PART_DETAILS_RECORD_LOAD_DATA = 'PART_DETAILS_RECORD_LOAD_DATA',
  PART_DETAILS_RECORD_LOAD_DATA_SUCCESS = 'PART_DETAILS_RECORD_LOAD_DATA_SUCCESS',
  PART_DETAILS_RECORD_LOAD_DATA_ERROR = 'PART_DETAILS_RECORD_LOAD_DATA_ERROR',

  PART_SEARCH_PAGE_CHANGED = 'PART_SEARCH_PAGE_CHANGED',
  PART_SEARCH_PAGE_SIZE_CHANGED = 'PART_SEARCH_PAGE_SIZE_CHANGED',

  ALERT_PAGE_CHANGED = 'ALERT_PAGE_CHANGED',
  ALERT_PAGE_SIZE_CHANGED = 'ALERT_PAGE_SIZE_CHANGED',

  ALERT_Detials_PAGE_CHANGED = 'ALERT_Detials_PAGE_CHANGED',
  ALERT_Details_PAGE_SIZE_CHANGED = 'ALERT_Details_PAGE_SIZE_CHANGED',
  
  P5_ALERT_Details_PAGE_CHANGED = 'P5_ALERT_Details_PAGE_CHANGED',
  P5_ALERT_Details_PAGE_SIZE_CHANGED = 'P5_ALERT_Details_PAGE_SIZE_CHANGED',

  PART_SEARCH_EXPORT_DATA = 'PART_SEARCH_EXPORT_DATA',
  PART_SEARCH_EXPORT_DATA_SUCCESS = 'PART_SEARCH_EXPORT_DATA_SUCCESS',
  PART_SEARCH_EXPORT_DATA_ERROR = 'PART_SEARCH_EXPORT_DATA_ERROR',

  ALERT_DELETE_RECORD = 'ALERT_DELETE_RECORD',
  ALERT_DELETE_RECORD_SUCCESS = 'ALERT_DELETE_RECORD_SUCCESS',
  ALERT_DELETE_RECORD_ERROR = 'ALERT_DELETE_RECORD_ERROR',

  SET_ALERT_DATA = 'SET_ALERT_DATA',

  ALERT_ADD_RECORD = 'ALERT_ADD_RECORD',
  ALERT_ADD_RECORD_SUCCESS = 'ALERT_ADD_RECORD_SUCCESS',
  ALERT_ADD_RECORD_ERROR = 'ALERT_ADD_RECORD_ERROR',

  GET_ALERT_RECORD_BY_ID = 'GET_ALERT_RECORD_BY_ID',
  GET_ALERT_RECORD_BY_ID_SUCCESS = 'GET_ALERT_RECORD_BY_ID_SUCCESS',
  GET_ALERT_RECORD_BY_ID_ERROR = 'GET_ALERT_RECORD_BY_ID_ERROR',

  GET_P5_ALERT_RECORD_BY_ID = 'GET_P5_ALERT_RECORD_BY_ID',
  GET_P5_ALERT_RECORD_BY_ID_SUCCESS = 'GET_P5_ALERT_RECORD_BY_ID_SUCCESS',
  GET_P5_ALERT_RECORD_BY_ID_ERROR = 'GET_P5_ALERT_RECORD_BY_ID_ERROR',

  ALERT_EDIT_RECORD = 'ALERT_EDIT_RECORD',
  ALERT_EDIT_RECORD_SUCCESS = 'ALERT_EDIT_RECORD_SUCCESS',
  ALERT_EDIT_RECORD_ERROR = 'ALERT_EDIT_RECORD_ERROR',

  EDIT_ALERT_DETAILS = 'EDIT_ALERT_DETAILS',
  EDIT_ALERT_DETAILS_SUCCESS = 'EDIT_ALERT_DETAILS_SUCCESS',
  EDIT_ALERT_DETAILS_ERROR = 'EDIT_ALERT_DETAILS_ERROR',

  ALERT_LOAD_DATA = 'ALERT_LOAD_DATA',
  ALERT_LOAD_DATA_SUCCESS = 'ALERT_LOAD_DATA_SUCCESS',
  ALERT_LOAD_DATA_ERROR = 'ALERT_LOAD_DATA_ERROR',

  P5_ALERT_LOAD_DATA = 'P5_ALERT_LOAD_DATA',
  P5_ALERT_LOAD_DATA_SUCCESS = 'P5_ALERT_LOAD_DATA_SUCCESS',
  P5_ALERT_LOAD_DATA_ERROR = 'P5_ALERT_LOAD_DATA_ERROR',

  ALERT_DETAILS_LOAD_DATA = 'ALERT_DETAILS_LOAD_DATA',
  ALERT_DETAILS_LOAD_DATA_SUCCESS = 'ALERT_DETAILS_LOAD_DATA_SUCCESS',
  ALERT_DETAILS_LOAD_DATA_ERROR = 'ALERT_DETAILS_LOAD_DATA_ERROR',
  GET_PRIORITIES = 'GET_PRIORITIES',
  GET_PRIORITIES_SUCCESS = 'GET_PRIORITIES_SUCCESS',
  GET_PRIORITIES_ERROR = 'GET_PRIORITIES_ERROR',
  EXPORT_ALL_ALERT_DATA = 'EXPORT_ALL_ALERT_DATA',
  EXPORT_ALL_ALERT_DATA_SUCCESS = 'EXPORT_ALL_ALERT_DATA_SUCCESS',
  EXPORT_ALL_ALERT_DATA_ERROR = 'EXPORT_ALL_ALERT_DATA_ERROR',

  SEND_SELECTED_ALERT = 'SEND_SELECTED_ALERT',
  SEND_SELECTED_ALERT_SUCCESS = 'SEND_SELECTED_ALERT_SUCCESS',
  SEND_SELECTED_ALERT_ERROR = 'SEND_SELECTED_ALERT_ERROR',

  PART_CBOM_EXPORT_DATA = '[PARTS] PART_CBOM_EXPORT_DATA',
  PART_CBOM_EXPORT_DATA_SUCCESS = '[PARTS] PART_CBOM_EXPORT_DATA_SUCCESS',
  PART_CBOM_EXPORT_DATA_ERROR = '[PARTS] PART_CBOM_EXPORT_DATA_ERROR',

  PART_SEARCH_CLEAR_GRID = 'PART_SEARCH_CLEAR_GRID',

  /////////////////////////////////////////////////CROSS SEARCH/////////////////////////////////////////////////
  CROSS_SEARCH_UPDATE_SEARCH_TEXT = 'CROSS_SEARCH_UPDATE_SEARCH_TEXT',
  CROSS_SEARCH_LOAD_DATA = 'CROSS_SEARCH_LOAD_DATA',
  CROSS_SEARCH_LOAD_DATA_SUCCESS = 'CROSS_SEARCH_LOAD_DATA_SUCCESS',
  CROSS_SEARCH_LOAD_DATA_ERROR = 'CROSS_SEARCH_LOAD_DATA_ERROR',

  CROSS_SEARCH_PAGE_CHANGED = 'CROSS_SEARCH_PAGE_CHANGED',
  CROSS_SEARCH_PAGE_SIZE_CHANGED = 'CROSS_SEARCH_PAGE_SIZE_CHANGED',

  CROSS_SEARCH_EXPORT_DATA = 'CROSS_SEARCH_EXPORT_DATA',
  CROSS_SEARCH_EXPORT_DATA_SUCCESS = 'CROSS_SEARCH_EXPORT_DATA_SUCCESS',
  CROSS_SEARCH_EXPORT_DATA_ERROR = 'CROSS_SEARCH_EXPORT_DATA_ERROR',

  CROSS_SEARCH_CLEAR_GRID = 'CROSS_SEARCH_CLEAR_GRID',

  /////////////////////////////////////////////////COMPONENTS/////////////////////////////////////////////////
  COMPONENTS_SELECTED_YEAR_UPDATE = 'COMPONENTS_SELECTED_YEAR_UPDATE',
  COMPONENTS_LOAD_DATA = 'COMPONENTS_LOAD_DATA',
  COMPONENTS_LOAD_DATA_SUCCESS = 'COMPONENTS_LOAD_DATA_SUCCESS',
  COMPONENTS_LOAD_DATA_ERROR = 'COMPONENTS_LOAD_DATA_ERROR',

  COMPONENTS_PAGE_CHANGED = 'COMPONENTS_PAGE_CHANGED',
  COMPONENTS_PAGE_SIZE_CHANGED = 'COMPONENTS_PAGE_SIZE_CHANGED',
  COMPONENTS_FILTER_CHNAGED = 'COMPONENTS_FILTER_CHNAGED',
  COMPONENTS_SORT_CHANGED = 'COMPONENTS_SORT_CHANGED',

  COMPONENTS_EXPORT_DATA = 'COMPONENTS_EXPORT_DATA',
  COMPONENTS_EXPORT_DATA_SUCCESS = 'COMPONENTS_EXPORT_DATA_SUCCESS',
  COMPONENTS_EXPORT_DATA_ERROR = 'COMPONENTS_EXPORT_DATA_ERROR',

  Alert_Details_FiLTER_CHANGED = 'Alert_Details_FiLTER_CHANGED',
  Alert_Details_SORT_CHANGED = 'Alert_Details_SORT_CHANGED',

  p5_Alert_Details_FILTER_CHANGED = 'p5_Alert_Details_FILTER_CHANGED',
  p5_Alert_Details_SORT_CHANGED = 'p5_Alert_Details_SORT_CHANGED',

  COMPONENTS_CLEAR_GRID = 'COMPONENTS_CLEAR_GRID',

  LOAD_COMPONENT = 'LOAD_COMPONENT',
  LOAD_COMPONENT_SUCCESS = 'LOAD_COMPONENT_SUCCESS',
  LOAD_COMPONENT_ERROR = 'LOAD_COMPONENT_ERROR',

  /////////////////////////////////////////////////CBOM/////////////////////////////////////////////////
  CBOM_SELECTED_YEAR_UPDATE = 'CBOM_SELECTED_YEAR_UPDATE',
  CBOM_LOAD_DATA = 'CBOM_LOAD_DATA',
  CBOM_LOAD_DATA_SUCCESS = 'CBOM_LOAD_DATA_SUCCESS',
  CBOM_LOAD_DATA_ERROR = 'CBOM_LOAD_DATA_ERROR',

  CBOM_PAGE_CHANGED = 'CBOM_PAGE_CHANGED',
  CBOM_PAGE_SIZE_CHANGED = 'CBOM_PAGE_SIZE_CHANGED',
  CBOM_FILTER_CHNAGED = 'CBOM_FILTER_CHNAGED',
  CBOM_SORT_CHANGED = 'CBOM_SORT_CHANGED',

  GET_CONTRACT_MANFACTURER = 'GET_CONTRACT_MANFACTURER',
  GET_CONTRACT_MANFACTURER_SUCCESS = 'GET_CONTRACT_MANFACTURER_SUCCESS',
  GET_CONTRACT_MANFACTURER_ERROR = 'GET_CONTRACT_MANFACTURER_ERROR',
  CBOM_CLEAR_GRID = 'CBOM_CLEAR_GRID',

  GET_CMT_ALERT_PART_DETAILS_BY_ID = 'GET_CMT_ALERT_PART_DETAILS_BY_ID',
  GET_CMT_ALERT_PART_DETAILS_BY_ID_SUCCESS = 'GET_CMT_ALERT_PART_DETAILS_BY_ID_SUCCESS',
  GET_CMT_ALERT_PART_DETAILS_BY_ID_ERROR = 'GET_CMT_ALERT_PART_DETAILS_BY_ID_ERROR',

  GET_P5_ALERT_PART_DETAILS_BY_ID = 'GET_P5_ALERT_PART_DETAILS_BY_ID',
  GET_P5_ALERT_PART_DETAILS_BY_ID_SUCCESS = 'GET_P5_ALERT_PART_DETAILS_BY_ID_SUCCESS',
  GET_P5_ALERT_PART_DETAILS_BY_ID_ERROR = 'GET_P5_ALERT_PART_DETAILS_BY_ID_ERROR',

  ADD_CMT_ALERT_PART_COMMENT = 'ADD_CMT_ALERT_PART_COMMENT',
  ADD_CMT_ALERT_PART_COMMENT_SUCCESS = 'ADD_CMT_ALERT_PART_COMMENT_SUCCESS',
  ADD_CMT_ALERT_PART_COMMENT_ERROR = 'ADD_CMT_ALERT_PART_COMMENT_ERROR',

  GET_USERS = 'GET_USERS',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_ERROR = 'GET_USERS_ERROR',
}

//////////////////////////////////////////Part Search/////////////////////////////////
export class UpdateSearchText implements Action {
  readonly type = ActionTypes.PART_SEARCH_UPDATE_SEARCH_TEXT;
  constructor(public filterText?: string) { }
}

export class PartSearchLoadData implements Action {
  readonly type = ActionTypes.PART_SEARCH_LOAD_DATA;
  constructor(public page?: number, public size?: number) { }
}

export class PartSearchPageChanged implements Action {
  readonly type = ActionTypes.PART_SEARCH_PAGE_CHANGED;
  constructor(public page?: number) { }
}

export class PartSearchPageSizeChanged implements Action {
  readonly type = ActionTypes.PART_SEARCH_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }
}

export class PartSearchLoadDataSuccess implements Action {
  readonly type = ActionTypes.PART_SEARCH_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class PartSearchLoadDataError implements Action {
  readonly type = ActionTypes.PART_SEARCH_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}

export class PartDetailsRecordLoadData implements Action {
  readonly type = ActionTypes.PART_DETAILS_RECORD_LOAD_DATA;
  constructor(public RecordId?: number,public yearId?:string) { }
}
export class PartDetailsRecordLoadDataSuccess implements Action {
  readonly type = ActionTypes.PART_DETAILS_RECORD_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class PartDetailsRecordLoadDataError implements Action {
  readonly type = ActionTypes.PART_DETAILS_RECORD_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}
export class PartSearchExportGrid implements Action {
  readonly type = ActionTypes.PART_SEARCH_EXPORT_DATA;
  constructor() { }
}

export class PartSearchExportGridSucees implements Action {
  readonly type = ActionTypes.PART_SEARCH_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) { }
}

export class PartSearchExportGridError implements Action {
  readonly type = ActionTypes.PART_SEARCH_EXPORT_DATA_ERROR;
  constructor(public payload: any) { }
}

export class PartSearchClearGrid implements Action {
  readonly type = ActionTypes.PART_SEARCH_CLEAR_GRID;
  constructor() { }
}

//////////////////////////////////////////Cross Search/////////////////////////////////

export class UpdateCrossSearchText implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_UPDATE_SEARCH_TEXT;
  constructor(public filterText?: string) { }
}

export class CrossSearchLoadData implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_LOAD_DATA;
  constructor(public page?: number, public size?: number) { }
}

export class CrossSearchPageChanged implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_PAGE_CHANGED;
  constructor(public page?: number) { }
}

export class CrossSearchPageSizeChanged implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }
}

export class CrossSearchLoadDataSuccess implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class CrossSearchLoadDataError implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}

export class CrossSearchExportGrid implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_EXPORT_DATA;
  constructor() { }
}

export class CrossSearchExportGridSucees implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) { }
}

export class CrossSearchExportGridError implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_EXPORT_DATA_ERROR;
  constructor(public payload: any) { }
}

export class CrossSearchClearGrid implements Action {
  readonly type = ActionTypes.CROSS_SEARCH_CLEAR_GRID;
  constructor() { }
}

//////////////////////////////////////////COMPONENTS/////////////////////////////////
export class UpdateComponentsSelectedYear implements Action {
  readonly type = ActionTypes.COMPONENTS_SELECTED_YEAR_UPDATE;
  constructor(public filterText?: string) { }
}

export class ComponentsLoadData implements Action {
  readonly type = ActionTypes.COMPONENTS_LOAD_DATA;
  constructor(
    public yearId?: number,
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) { }
}

export class ComponentsPageChanged implements Action {
  readonly type = ActionTypes.COMPONENTS_PAGE_CHANGED;
  constructor(public page?: number) { }
}

export class ComponentsPageSizeChanged implements Action {
  readonly type = ActionTypes.COMPONENTS_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }
}

export class ComponentsFilterChanged implements Action {
  readonly type = ActionTypes.COMPONENTS_FILTER_CHNAGED;
  constructor(public filter?: IFilterModel) { 
 
  }
}

export class CBomSortChanged implements Action {
  readonly type = ActionTypes.CBOM_SORT_CHANGED;
  constructor(public sort?: ISortModel) { }
}
 
export class CBomPageChanged implements Action {
  readonly type = ActionTypes.CBOM_PAGE_CHANGED;
  constructor(public page?: number) { }
}

export class CBomPageSizeChanged implements Action {
  readonly type = ActionTypes.CBOM_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }
}

export class CBomFilterChanged implements Action {
  readonly type = ActionTypes.CBOM_FILTER_CHNAGED;
  constructor(public filter?: IFilterModel) { 
  }
}

export class ComponentsSortChanged implements Action {
  readonly type = ActionTypes.COMPONENTS_SORT_CHANGED;
  constructor(public sort?: ISortModel) { }
}
export class ComponentsLoadDataSuccess implements Action {
  readonly type = ActionTypes.COMPONENTS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class ComponentsLoadDataError implements Action {
  readonly type = ActionTypes.COMPONENTS_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}
export class ComponentsExportGrid implements Action {
  readonly type = ActionTypes.COMPONENTS_EXPORT_DATA;
  constructor(
    public filename?: any,
    public yearId?: number,
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
    ) { }
}

export class ComponentsExportGridSuccess implements Action {
  readonly type = ActionTypes.COMPONENTS_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer, public fileName: any) { }
}

export class ComponentsExportGridError implements Action {
  readonly type = ActionTypes.COMPONENTS_EXPORT_DATA_ERROR;
  constructor(public payload: any) { }
}

export class ComponentsClearGrid implements Action {
  readonly type = ActionTypes.COMPONENTS_CLEAR_GRID;
  constructor() { }
}

export class LoadComponent implements Action {
  readonly type = ActionTypes.LOAD_COMPONENT;
  constructor(public comId: string) { }
}

export class LoadComponentSuccess implements Action {
  readonly type = ActionTypes.LOAD_COMPONENT_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadComponentError implements Action {
  readonly type = ActionTypes.LOAD_COMPONENT_ERROR;
  constructor(public payload: any) { }
}

//////////////////////////////////////////CBOM/////////////////////////////////
export class CbomLoadData implements Action {
  readonly type = ActionTypes.CBOM_LOAD_DATA;
  constructor(
    public yearId?: number,
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[],
    public manId?: number
    ) { }
}
export class CbomLoadDataSuccess implements Action {
  readonly type = ActionTypes.CBOM_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class CbomLoadDataError implements Action {
  readonly type = ActionTypes.CBOM_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}

export class GetContractManufacturer implements Action {
  readonly type = ActionTypes.GET_CONTRACT_MANFACTURER;
  constructor() { }
}

export class GetContractManufacturerSuccess implements Action {
  readonly type = ActionTypes.GET_CONTRACT_MANFACTURER_SUCCESS;
  constructor(public payload: any) { }
}

export class GetContractManufacturerError implements Action {
  readonly type = ActionTypes.GET_CONTRACT_MANFACTURER_ERROR;
  constructor(public payload: any) { }
}

export class CbomClearGrid implements Action {
  readonly type = ActionTypes.CBOM_CLEAR_GRID;
  constructor() { }
}

export class PartCBOMExportGrid implements Action {
  readonly type = ActionTypes.PART_CBOM_EXPORT_DATA;
  constructor(
    public filename?: any, 
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[],
    public yearId?: any, 
    public cm?: any
    ) { }
}
export class PartCBOMExportGridError implements Action {
  readonly type = ActionTypes.COMPONENTS_EXPORT_DATA_ERROR;
  constructor(public payload: any) { }
}

export class PartCBOMExportGridSucees implements Action {
  readonly type = ActionTypes.PART_CBOM_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer, public fileName: any) { }
}
/*-----------------------------------alerts-------------------------------*/
export class AlertLoadData implements Action {
  readonly type = ActionTypes.ALERT_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) { }
}

export class AlertLoadDataSucees implements Action {
  readonly type = ActionTypes.ALERT_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class AlertLoadDataError implements Action {
  readonly type = ActionTypes.ALERT_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}
export class p5AlertLoadData implements Action {
  readonly type = ActionTypes.P5_ALERT_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) { }
}

export class p5AlertLoadDataSucees implements Action {
  readonly type = ActionTypes.P5_ALERT_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class p5AlertLoadDataError implements Action {
  readonly type = ActionTypes.P5_ALERT_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}
export class AlertPageChanged implements Action {
  readonly type = ActionTypes.ALERT_PAGE_CHANGED;
  constructor(public page?: number) { }
}

export class AlertPageSizeChanged implements Action {
  readonly type = ActionTypes.ALERT_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }
}

export class AlertDelete implements Action {
  readonly type = ActionTypes.ALERT_DELETE_RECORD;
  constructor(public alertID: number) { }
}

export class AlertDeleteSuccess implements Action {
  readonly type = ActionTypes.ALERT_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) { }
}

export class AlertDeleteError implements Action {
  readonly type = ActionTypes.ALERT_DELETE_RECORD_ERROR;
  constructor(public payload: any) { }
}

export class AlertEdit implements Action {
  readonly type = ActionTypes.ALERT_EDIT_RECORD;
  constructor(public payload: any) { }
}

export class AlertEditSuccess implements Action {
  readonly type = ActionTypes.ALERT_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) { }
}

export class AlertEditError implements Action {
  readonly type = ActionTypes.ALERT_EDIT_RECORD_ERROR;
  constructor(public payload: any) { }
}

export class EditAlertDetails implements Action {
  readonly type = ActionTypes.EDIT_ALERT_DETAILS;
  constructor(public payload: any,public alertId: number) { }
}

export class EditAlertDetailsSuccess implements Action {
  readonly type = ActionTypes.EDIT_ALERT_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}

export class EditAlertDetailsError implements Action {
  readonly type = ActionTypes.EDIT_ALERT_DETAILS_ERROR;
  constructor(public payload: any) { }
}

export class AlertAdd implements Action {
  readonly type = ActionTypes.ALERT_ADD_RECORD;
  constructor(public payload: any) { }
}

export class AlertAddSuccess implements Action {
  readonly type = ActionTypes.ALERT_ADD_RECORD_SUCCESS;
  constructor(public payload: any) { }
}

export class AlertAddError implements Action {
  readonly type = ActionTypes.ALERT_ADD_RECORD_ERROR;
  constructor(public payload: any) { }
}
/*------------------------------alert details-----------------------------*/

export class ALertExportAllGrid implements Action {
  readonly type = ActionTypes.EXPORT_ALL_ALERT_DATA;
  constructor(public fileName?: any,
    public page?: number,
    public size?: number,
    public P5AlertId?:number,
    public CmtAlertId?:number,
    public selectedALert?:number[],
    public filter?: any[],
    public sort?: SortDescriptor[]) {}
}

export class ALertExportAllGridSucees implements Action {
  readonly type = ActionTypes.EXPORT_ALL_ALERT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer, public fileName: any) {}
}

export class ALertExportAllGridError implements Action {
  readonly type = ActionTypes.EXPORT_ALL_ALERT_DATA_ERROR;
  constructor(public payload: any) {}
}


export class SendSelectedAlerts implements Action {
  readonly type = ActionTypes.SEND_SELECTED_ALERT;
  constructor(public payload: any, public cmtALertId?:number,public P5AlertId?:number) {

  }
}

export class SendSelectedAlertsSucees implements Action {
  readonly type = ActionTypes.SEND_SELECTED_ALERT_SUCCESS;
  constructor(public payload: any) {}
}

export class SendSelectedAlertsError implements Action {
  readonly type = ActionTypes.SEND_SELECTED_ALERT_ERROR;
  constructor(public payload: any) {}
}


export class AlertDetailsLoadData implements Action {
  readonly type = ActionTypes.ALERT_DETAILS_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[],
    public alertId?: number
  ) { }
}
export class SetAlertData implements Action {
  readonly type = ActionTypes.SET_ALERT_DATA;
  constructor(public alertID?:number) {}
}
export class AlertDetailsLoadDataSucees implements Action {
  readonly type = ActionTypes.ALERT_DETAILS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class AlertDetailsLoadDataError implements Action {
  readonly type = ActionTypes.ALERT_DETAILS_LOAD_DATA_ERROR;
  constructor(public payload: any) { }
}
export class AlertDetailsSortChanged implements Action {
  readonly type = ActionTypes.Alert_Details_SORT_CHANGED;
  constructor(public sort?: ISortModel) { }
}



export class AlertDetailsFilterChanged implements Action {
  readonly type = ActionTypes.Alert_Details_FiLTER_CHANGED;
  constructor(public filter?: IFilterModel) { }
}
export class AlertDetailsPageChanged implements Action {
  readonly type = ActionTypes.ALERT_Detials_PAGE_CHANGED;
  constructor(public page?: number) { }
}
export class AlertDetailsPageSizeChanged implements Action {
  readonly type = ActionTypes.ALERT_Details_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }}
export class GetAlertDetailsById implements Action {
  readonly type = ActionTypes.GET_ALERT_RECORD_BY_ID;
  constructor(public AlertId:any) {}
}

export class GetAlertDetailsByIdSuccess implements Action {
  readonly type = ActionTypes.GET_ALERT_RECORD_BY_ID_SUCCESS;
  constructor(public payload: any) {}
}

export class GetAlertDetailsByIdError implements Action {
  readonly type = ActionTypes.GET_ALERT_RECORD_BY_ID_ERROR;
  constructor(public payload: any) {}
}
export class GetP5AlertDetailsById implements Action {
  readonly type = ActionTypes.GET_P5_ALERT_RECORD_BY_ID;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[],
    public parentId?: number
  ) { }
}
 
export class GetP5AlertDetailsByIdSuccess implements Action {
  readonly type = ActionTypes.GET_P5_ALERT_RECORD_BY_ID_SUCCESS;
  constructor(public payload: any) {}
}
export class GetP5AlertDetailsByIdError implements Action {
  readonly type = ActionTypes.GET_P5_ALERT_RECORD_BY_ID_ERROR;
  constructor(public payload: any) {}
}

export class P5AlertDetailsSortChanged implements Action {
  readonly type = ActionTypes.p5_Alert_Details_SORT_CHANGED;
  constructor(public sort?: ISortModel) { }
}

export class P5AlertDetailsFilterChanged implements Action {
  readonly type = ActionTypes.p5_Alert_Details_FILTER_CHANGED;
  constructor(public filter?: IFilterModel) { 
 
  }
}
export class P5AlertDetailsPageChanged implements Action {
  readonly type = ActionTypes.P5_ALERT_Details_PAGE_CHANGED;
  constructor(public page?: number) { }
}
export class P5AlertDetailsPageSizeChanged implements Action {
  readonly type = ActionTypes.P5_ALERT_Details_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }}


  export class GetCMTAlertPartDetailsById implements Action {
    readonly type = ActionTypes.GET_CMT_ALERT_PART_DETAILS_BY_ID;
    constructor(
      public alertPartDetailsId?: number
    ) { }
  }
   
  export class GetCMTAlertPartDetailsByIdSuccess implements Action {
    readonly type = ActionTypes.GET_CMT_ALERT_PART_DETAILS_BY_ID_SUCCESS;
    constructor(public payload: any) {}
  }
  export class GetCMTAlertPartDetailsByIdError implements Action {
    readonly type = ActionTypes.GET_CMT_ALERT_PART_DETAILS_BY_ID_ERROR;
    constructor(public payload: any) {}
  }

  export class GetP5AlertPartDetailsById implements Action {
    readonly type = ActionTypes.GET_P5_ALERT_PART_DETAILS_BY_ID;
    constructor(
      public alertPartDetailsId?: number
    ) { }
  }
   
  export class GetP5AlertPartDetailsByIdSuccess implements Action {
    readonly type = ActionTypes.GET_P5_ALERT_PART_DETAILS_BY_ID_SUCCESS;
    constructor(public payload: any) {}
  }
  export class GetP5AlertPartDetailsByIdError implements Action {
    readonly type = ActionTypes.GET_P5_ALERT_PART_DETAILS_BY_ID_ERROR;
    constructor(public payload: any) {}
  }

  export class AddCMTAlertPartComment implements Action {
    readonly type = ActionTypes.ADD_CMT_ALERT_PART_COMMENT;
    constructor(public payload: any) { }
  }
   
  export class AddCMTAlertPartCommentSuccess implements Action {
    readonly type = ActionTypes.ADD_CMT_ALERT_PART_COMMENT_SUCCESS;
    constructor(public payload: any) {}
  }
  export class AddCMTAlertPartCommentError implements Action {
    readonly type = ActionTypes.ADD_CMT_ALERT_PART_COMMENT_ERROR;
    constructor(public payload: any) {}
  }

  export class GetUsers implements Action {
    readonly type = ActionTypes.GET_USERS;
    constructor() { }
  }
   
  export class GetUsersSuccess implements Action {
    readonly type = ActionTypes.GET_USERS_SUCCESS;
    constructor(public payload: any) {}
  }
  export class GetUsersError implements Action {
    readonly type = ActionTypes.GET_USERS_ERROR;
    constructor(public payload: any) {}
  }

  export class GetPriorities implements Action {
    readonly type = ActionTypes.GET_PRIORITIES;
    constructor() { }
  }
   
  export class GetPrioritiesSuccess implements Action {
    readonly type = ActionTypes.GET_PRIORITIES_SUCCESS;
    constructor(public payload: any) {}
  }
  export class GetPrioritiesError implements Action {
    readonly type = ActionTypes.GET_PRIORITIES_ERROR;
    constructor(public payload: any) {}
  }