<div style="display: grid">
  <div>
    <p style="float: left">sample rows from the ticket</p>
    <app-nav-bar [items]="navBarButtons" style="float: right"></app-nav-bar>
  </div>
  <div style="width: 99%; overflow-x: auto">
    <app-grid
      [rowData]="gridData"
      [headers]="headers"
      [actions]="action"
      filterable="false"
      [hidePager]="true"
      [width]="150"
      [height]="height"
    ></app-grid>
  </div>
</div>
<ng-container>
  <app-spinner [hasOverlay]="true" *ngIf="isLoading"></app-spinner>
</ng-container>