<!-- *ngIf="! hasPopOver" -->
<ng-container [ngSwitch]="buttonType">
  <div class="btn-container">
    <div *ngIf="!hasPopOver">
      <button
        *ngSwitchCase="'button'"
        [icon]="buttonIcon"
        kendoButton
        [themeColor]="themeColor"
        (click)="onButtonClick()"
        [disabled]="disabled"
      >
        {{ value }}
      </button>
    </div>

    <div
      *ngIf="hasPopOver && popover"
      kendoPopoverAnchor
      [popover]="popover"
      [showOn]="showPopoverOption">
      <button
        *ngSwitchCase="'button'"
        [icon]="buttonIcon"
        kendoButton
        [themeColor]="themeColor"
        (click)="onButtonClick()"
        [disabled]="disabled"
      >
        {{ value }}
      </button>
    </div>

    <kendo-dropdownbutton
      *ngSwitchCase="'buttonDropdown'"
      [data]="dropDownItems"
      [icon]="buttonIcon"
      [disabled]="disabledDropDown"
      themeColor="primary"
      (itemClick)="onDropdownItemClick($event)"
    >
      {{ value }}
    </kendo-dropdownbutton>

    <kendo-splitbutton
      *ngSwitchCase="'splitButton'"
      [data]="dropDownItems"
      themeColor="primary"
      [icon]="buttonIcon"
      (itemClick)="onDropdownItemClick($event)"
      (buttonClick)="onDropDpwnClick(value)"
    >
      {{ value }}
    </kendo-splitbutton>
  </div>
</ng-container>

<app-popover
  #popoverContainer
  *ngIf="hasPopOver"
  [position]="position"
  [dropdownData]="dropDownItems"
  [width]="'300'"
  [htmlData]="htmlData"
  [showdialog]="showdialog"
  [description]="popOverContent"
  (clickButtonEvent)="onButtonClick()"
  [openedComponent]="openedComponent"
></app-popover>
