import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/core/api/api.service';
import { currentQuoteActionSelector } from 'src/app/features/admin/pages/quotes/stores/quotes.selector';
import { currentSupplierResponseActionSelector } from 'src/app/features/upload/stores/upload.selector';
import { IAppState } from 'src/app/shared/shared.interface';
import { environment } from 'src/environments/environment';
import { MappedDataRequest } from './quotes.request';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  private fileId: any;
  private supplierFileId: any;

  constructor(
    private apiService: ApiService,
    private _http: HttpClient,
    private store: Store<IAppState>
  ) {
    this.store.select(currentQuoteActionSelector).subscribe((data) => {
      this.fileId = data.fileId;
    });
    this.store.select(currentSupplierResponseActionSelector).subscribe((data) => {
      this.supplierFileId = data.fileId;
    });
  }

  public normalizeMappedColumnsData(
    mappedColumns: any,
    start: any,
    tempQuoteId: any
  ) {
    const request = MappedDataRequest(
      mappedColumns,
      start,
      this.fileId,
      tempQuoteId
    );
    return this.apiService.post<any>(
      `${environment.baseUrls.serviceURL}/rfq/RFQProccessing/normalize`,
      request
    );
  }
}
